import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import Cropper from "./Cropper";
import "react-image-crop/dist/ReactCrop.css";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { FaArrowRotateRight } from "react-icons/fa6";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from 'react-redux';
import { setExtractedData } from "../../redux/actions";
import { Button } from "@mui/material";
import {ReactComponent as UploadPictureIcon} from "../../assets/SVG/upload_profile.svg";


 interface EditProfilePictureProps {
    base64ImageUrl:string;
    onClose():void;
}

const EditProfilePictureModal:React.FC<EditProfilePictureProps> = 
({base64ImageUrl,onClose}) => {
  const resumeDetails = useSelector((state:any) => state.extractedData);
  const [zoomValue,setZoomValue] = useState(10);
  const [rotate, setRotate] = useState<number>(0);
  const [isCrop,setIsCrop] = useState<boolean>(false);
  const [brightness, setBrightness] = useState(1);
  const [contrast, setContrast] = useState(1);
  const [grayscale, setGrayscale] = useState(0);
  const [sepia, setSepia] = useState(0);
  const [isImageReady, setImageReady] = useState<boolean>(false);
  const [currentBase,setCurrentBase] = useState<string>("");
  const uploadPhotoRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const editorRef = useRef<AvatarEditor | null>(null);
  const zoomPrevRef = useRef<number>(10);
  const brightnessPrevRef = useRef(1);
  const contrastPrevRef = useRef<number>(1);
  const grayscalePrevRef = useRef<number>(0);
  const sepiaPrevRef = useRef<number>(0);


  const getCurrentEditorRef:any = ():string => {
    if (editorRef.current && isImageReady) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const ctx = canvas.getContext('2d');
      
      if(ctx) {   
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
  
        ctx.filter = `brightness(${brightness}) contrast(${contrast}) sepia(${sepia}) grayscale(${grayscale})`;
        ctx.drawImage(canvas, 0, 0);
        return canvas.toDataURL('image/jpeg');
      }
    }
    return "";
  }

  const handleZoomChange = (e:React.ChangeEvent<HTMLInputElement>) => {
     if(e?.target?.value) {
      const newValue = parseInt(e.target.value);
        setZoomValue(newValue);
     }
  };

  const handleBrightnessChange = (e:React.ChangeEvent<HTMLInputElement>) => {

    if(e?.target?.value) {
      const newValue = parseFloat(e.target.value);
      setBrightness(newValue);
     }
  }

  const handleContrastChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e?.target?.value) {
      const newValue = parseFloat(e.target.value);
      setContrast(newValue);
     }
  }

  const handleGrayscaleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e?.target?.value) {
      const newValue = parseFloat(e.target.value);
      setGrayscale(newValue);
     }
  }

  const handleSepiaChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    if(e?.target?.value) {
      const newValue = parseFloat(e.target.value);
      setSepia(newValue);
     }
  }


  const handleReset = () => {
    setZoomValue(10);
    setBrightness(1);
    setContrast(1);
    setGrayscale(0);
    setSepia(0);
    setRotate(0);
    setIsCrop(false);
    setCurrentBase(resumeDetails?.profile_Picture);
  }


  const handleProfileSave = () => {
    if (editorRef?.current) {
      if(getCurrentEditorRef()) {
        setCurrentBase(getCurrentEditorRef());
        dispatch(setExtractedData({ ...resumeDetails, profile_Picture: getCurrentEditorRef() }));
        onClose();
      }
      
    }
  }

  const handleProfileCancel = () => {
    onClose();
  }

  const rotateLeft = () => {
    setRotate((prev) => prev-=90)
  }

  const rotateRight = () => {
    setRotate((prev) => prev+=90)
  }

  const handleCurrentBaseChange = () => {
      if(editorRef?.current) {
      if(getCurrentEditorRef()) {
        setCurrentBase(getCurrentEditorRef()); 
      }
    }
  }

  const handleCropClick = () => {
    if(!isCrop) {
      handleCurrentBaseChange();
    }
    setIsCrop((prev)=> !prev);
    resetAvatarValue();
  }

  const resetAvatarValue = () => {
     setRotate(0);
     setZoomValue(10);
     setBrightness(1);
     setContrast(1);
     setGrayscale(0);
     setSepia(0);
  }

  const onImageReady = useCallback(() => {
    setImageReady(true);
  },[])

//   useEffect(()=>{
//     if(resumeDetails.profile_Picture) {
//       setInitialBase64Image(resumeDetails.profile_Picture);
//       handleReset()
//     }
// },[resumeDetails.profile_Picture])

  const profileChangeHandler = () => {
    uploadPhotoRef?.current?.click();
  }

  const handleProfilePhotoUpload = (e:any):void => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          dispatch(setExtractedData({ ...resumeDetails, profile_Picture: reader.result.toString() }));
        } else {
          console.error('Failed to read file');
        }
      };
      reader.readAsDataURL(file);
    }
    }
  
    console.log(resumeDetails.profile_Picture,"Modal Rendered")
   return <div className="Edit-profile-picture__modal--backdrop">
      <div className="Edit-profile-picture__modal--container">
        <div className="Edit-profile-picture__modal--container_content">
          <div className="avatar-container">
             {/* <div className="avatar-wrapper crop-wrapper" style={!isCrop ? {display:"none"} : {}}>
             {currentBase && <Cropper imageToCrop={currentBase} isCrop={isCrop}
             setIsCrop={setIsCrop} setCurrentBase={setCurrentBase} resetAvatarValue={resetAvatarValue}/>}
             </div> */}
              
              <div className="avatar-wrapper" style={isCrop ? {display:"none"} : {}}> 
                <div className="upload-picture-container">

                  <UploadPictureIcon onClick={profileChangeHandler}/>
                 <input
                  type="file"
                  id="upload-photo"
                  accept="image/*"
                  onClick={(e) => {
                    const event = e.target as HTMLInputElement;
                    event.value = "";
                  }}
                  onChange={handleProfilePhotoUpload}
                  ref={uploadPhotoRef}
                  hidden
                />
                  </div>
            <AvatarEditor
            ref={editorRef}
            image={currentBase ? currentBase
              : resumeDetails?.profile_Picture}
            border={0}
            color={[0, 0, 0, 0.72]}
            scale={zoomValue / 10}
            rotate={rotate}
            onImageReady={onImageReady}
            style={{
              filter: `brightness(${brightness}) contrast(${contrast}) sepia(${sepia}) grayscale(${grayscale})`,
            }}
            />
           </div>
          
          </div>

          <div className="control-section">
           
              <div>
              <label>Zoom:</label>
              <input type="range" min={10} max={30} step={0.1} value={zoomValue}
              onChange={handleZoomChange}
              />
              </div>

            {/* <div className="control-element crop-wrapper">
              Crop:
              <div className="crop-switch-container"> 
              <div className="switch-container">
               <label className="switch">
                  <input type="checkbox" checked={isCrop}
                   onChange={handleCropClick}
                    />
                  <span className="slider round"></span>
                </label>
              </div>
              </div>
            </div> */}

            <div>
              <label>Brightness:</label>
               <input type="range" min={0} max={2} step={0.1} value={brightness} onChange={handleBrightnessChange} />
            </div>

            <div>
              <label>Contrast:</label>
              <input  type="range" min={0} max={2} step={0.1} value={contrast} onChange={handleContrastChange} />
            </div>

            <div>
              <label>Sepia:</label>
              <input  type="range" min={0} max={1} step={0.1} value={sepia} onChange={handleSepiaChange} />
            </div>

            <div>
              <label>Grayscale:</label>
              <input  type="range" min={0} max={1} step={0.1} value={grayscale} onChange={handleGrayscaleChange} />
            </div>

            <div className="rotate-wrapper">
              <div className="control-element">
              <label>Rotate:</label>
              <div className="rotate-button-container">
                <Button><FaArrowRotateLeft size="1.5rem" color="silver" onClick={rotateLeft}/></Button>
                <Button><FaArrowRotateRight size="1.5rem" color="silver" onClick={rotateRight}/></Button>
              </div>
              </div>
            </div>

            

          <div className="close-btn" onClick={onClose}>&times;</div>
        </div> 

        <footer className="footer">
          <div className="grid">
          <div className="col text-left"><button onClick={handleReset}>Reset</button></div>
          <div className="col text-right"><button onClick={handleProfileCancel}>Cancel</button><button onClick={handleProfileSave}>Save</button></div>
        </div>
        </footer> 
      </div>
    </div> 
  </div>
};

export default EditProfilePictureModal;