import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const SecondaryButton = styled(Button)(({ theme }) => ({
  // borderRadius: '8px',
  padding: '8px',
  // textTransform: 'none',
  backgroundColor: '#019FFF',
  color: '#fff',
  // '&:hover': {
  //   backgroundColor: '#5a6268',
  // },
}));