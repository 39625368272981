// RefContext.tsx
import React, { createContext, useContext, useRef } from 'react';

const RefContext = createContext<any>(null);

export const RefProvider = ({ children }: { children: React.ReactNode }) => {
    const sectionRefs = useRef<{ [key: string]: React.RefObject<HTMLElement> }>({});

    const registerRef = (section: string) => {
        if (!sectionRefs.current[section]) {
            sectionRefs.current[section] = React.createRef<HTMLElement>();
        }
        return sectionRefs.current[section];
    };

    return (
        <RefContext.Provider value={{ sectionRefs: sectionRefs.current, registerRef }}>
            {children}
        </RefContext.Provider>
    );
};

export const useRefContext = () => useContext(RefContext);