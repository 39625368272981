import { Typography } from "@mui/material";
import { CourseType } from "../utils";

interface ISummaryDetails {
    summaryDetails:CourseType[]
    title:string
}

const SummarySectionDetailsList = ({summaryDetails,title}:ISummaryDetails) => {
  return (
    <>
        <Typography className="heading">{title}</Typography>
        <ul className={"experience-container"}>
        {summaryDetails?.map((detail, index: number) => (
        <li className={"experience"} key={index}>
        <Typography className='sub-heading'>{detail.organization}</Typography>
        {detail?.startDate && detail?.endDate && <Typography className='sub-heading'>{JSON.stringify(detail?.["startDate"])} - {JSON.stringify(detail?.["endDate"])}</Typography>}
        {detail?.courseDescription && <Typography>{detail.courseDescription}</Typography>}     
        </li>
    ))}
    </ul>
    </>
  )
}
export default SummarySectionDetailsList;