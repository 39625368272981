import React from 'react';
// import styles from "../CommonComponents/ListTypeSkills.module.scss";

type IComponentProps = {
    skills: string[]|string|{label:string,value:string}[],
    skillStyle?: string
}   

const ListTypeSkills = ({skills,skillStyle}:IComponentProps) => {
    if(typeof skills === "string") {
        return <ul className={"skill-container"}>
          <li className={skillStyle} >{skills}</li>
        </ul>
       }
    else if(Array.isArray(skills) && typeof skills?.[0] === "object") {
      return <ul className={"skill-container"}>
      {(skills as {label:string,value:string}[])?.map((skill:{label:string,value:string}, index:number) => (
        <li className={skillStyle} key={index}>{skill?.label}</li>
    ))}
   </ul>  
    }else {
      return <ul className={"skill-container"}>
      {(skills as string[])?.map((skill:string, index:number) => (
        <li className={skillStyle} key={index}>{skill}</li>
    ))}
   </ul>  
    }
}

export default ListTypeSkills;