

type IComponentProps = {
    skills: string[]|string,
    skillStyle?: string
}   

const ListTypeSkills = ({skills,skillStyle}:IComponentProps) => {
    if(typeof skills === "string") {
        return <ul className={"skill-container"}>
          <li className={skillStyle} >{skills}</li>
        </ul>
       }
        return <ul className={"skill-container"}>
           {skills?.map((skill:string, index:number) => (
             <li className={skillStyle} key={index}>{skill}</li>
         ))}
        </ul>  
}

export default ListTypeSkills;