import { Alert, Box, Button, Container, CssBaseline, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from "@mui/material";
import { AuthInputFieldStyle, InputFieldCustomeStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../auth/storeToken";
import { SnackBarState } from "../auth/Login";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import AppBarHeader from "../../ProcessResume/AppBarHeader";
import SideDrawer from "../../ProcessResume/SideDrawer";
import { LockIcon, VisibilityOn } from "../Icons";


const ForgotPassword = () => {
    let token = useSelector((state:any) => state?.loggedInUserData?.token);

    //getting token from local storage
    if(!token ){
      const storedToken = getToken();
      if(storedToken) {
        token = storedToken;
      }
    }


    const [passwordData,setPasswordData] = useState<{currentPassword:string,newPassword:string,confirmNewPassword:""}>({
      currentPassword:"",
      newPassword:"",
      confirmNewPassword:""
    });
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError,setPasswordError] = useState({
      currentPasswordText:"",
      newPasswordText:"",
      newConfirmPasswordText:"",
    });
    const [snackBar, setSnackBar] = useState<SnackBarState>({open: false, message: "", severity: "success"});
    const navigate = useNavigate();

    const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownCurrentPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };

      const handleCloseSnackbar = () => {
        setSnackBar({
          ...snackBar,
          open: false
        });
        navigate("/");
      };

      const handleChangePassword = (e:React.ChangeEvent<HTMLInputElement>) => {
       
        if(!e.target) return;
        else {
          const {name,value} = e.target;
          setPasswordData((prev)=> ({
            ...prev,
            [name]: value
          }));
        }
      }

    const updatePassword = async () => {
     const response = await fetch('https://testapi.tekno.ai/api/change-password',{
        method:"POST",
        headers:{
          'Content-type': "application/json",
          'Authorization':`Bearer ${token}`,
        },
        body: JSON.stringify({
          currentPassword:passwordData.currentPassword,
          newPassword:passwordData.newPassword,
        })
      });

      if(!response.ok && response.status === 401) {
        setPasswordError(({
          currentPasswordText:"current password is incorrect",
          newPasswordText:"",
          newConfirmPasswordText:""
        }));
      }
      else if(response.ok && response.status === 200) {
        setSnackBar({
          open: true,
          severity: "success",
          message: 'password updated successfully.' 
        });
      }

    }

    const handlePasswordSubmit = (e:any) => {
      e.preventDefault();
      if(!passwordData?.currentPassword) {
        setPasswordError(({
          currentPasswordText:"current password is required",
          newPasswordText:"",
          newConfirmPasswordText:""
        }));
      }
      else if(!passwordData?.newPassword && !passwordData?.confirmNewPassword) {
        setPasswordError(({
          currentPasswordText:"",
          newPasswordText:"new password is required",
          newConfirmPasswordText:"confirm password is required"
        }));
      }
     else if(passwordData?.newPassword !== passwordData?.confirmNewPassword) {
        setPasswordError(({
          currentPasswordText:"",
          newPasswordText:"",
          newConfirmPasswordText:"The new password and confirm password fields must match.",
        }));
      }
     else if (passwordData?.currentPassword  && passwordData?.newPassword === passwordData.confirmNewPassword) {
      setPasswordError(({
        currentPasswordText:"",
        newPasswordText:"",
        newConfirmPasswordText:"",
      }));
       if(token) {
        updatePassword()
       }
      }
    }

      

 return (
  <Box>
    <Box className="app-bar-container">
      <AppBarHeader/>
    </Box>
    <Box className="side-drawer-container">
        <SideDrawer />
    </Box>
    <Container>
      <Grid container>
        <Box component="form" noValidate onSubmit={() => {}} sx={{ mt: 3 }}>
            <Typography component="h3" variant="h6" sx={{fontWeight:"600",fontSize:"1.85rem", mb: 2}} >
              Forgot Password
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" component="label" htmlFor="password" gutterBottom sx={{ fontWeight: "bold" }}>Current Password *</Typography>
                <TextField
                  required
                  fullWidth
                  id="current_password"
                  name="currentPassword"
                  type={showCurrentPassword ? 'text' : 'password'}
                  placeholder="Enter current password"
                  onChange={handleChangePassword}
                  error={!!passwordError?.currentPasswordText}
                  helperText={passwordError?.currentPasswordText}
                  sx={AuthInputFieldStyle}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ paddingRight: "0.8rem" }}
                        >
                          {showPassword ? <VisibilityOff fontSize="small" /> : <VisibilityOn />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="label" htmlFor="password" gutterBottom>New Password*</Typography>
                    <TextField
                      required
                      fullWidth
                      id="newPassword"
                      name="newPassword"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your new password"
                        sx={AuthInputFieldStyle}
                        onChange={handleChangePassword}
                        error={!!passwordError?.newPasswordText}
                        helperText={passwordError?.newPasswordText}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{ paddingRight: "0.8rem" }}
                              >
                                {showPassword ? <VisibilityOff fontSize="small" /> : <VisibilityOn />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="label" htmlFor="confirm_password" gutterBottom>Confirm New Password*</Typography>
                  <TextField
                    required
                    fullWidth
                    name="confirmNewPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirm_password"
                    placeholder="Re-enter your new password"
                    sx={AuthInputFieldStyle}
                    onChange={handleChangePassword}
                    error={!!passwordError?.newConfirmPasswordText}
                    helperText={passwordError?.newConfirmPasswordText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{ paddingRight: "0.8rem" }}
                          >
                            {showPassword ? <VisibilityOff fontSize="small" /> : <VisibilityOn />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 3, mb: 2, background:"#012fff",fontWeight:"600",borderRadius:"10px",textTransform:"none" , fontSize:"1rem"}}
                onClick={handlePasswordSubmit}
              >
                Update
              </Button>
            </Box>
          </Grid>
      </Container>
      <Snackbar open={snackBar.open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackBar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
  </Box>
 )
}

export default ForgotPassword;