import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import {ReactComponent as CardIcon}  from "../../assets/SVG/cardIcon.svg";

interface EditHeaderDetails {
    details : {
    heading:string,
    subHeading?:string,
    startDate?:string,
    endDate?:string,
    city?:string,
    company?:string,
    index?:number,
    },
    handleSave:() => void;
    handleCancel:() => void;
}

const CardEditHeader:React.FC<EditHeaderDetails> = ({details, handleSave, handleCancel}) => {
  return (
    <Box>
    <Card className="education-card-container" sx={{ width: "100%",boxShadow:"none" }}>
      <CardContent className='card-content edit-header'         
      sx={{
          '&:last-child': {
            paddingBottom: 0, // Remove padding from last child
          },
        }}
>       
        <Box className="top-section">
          <Box className="top-section-left">
              <CardIcon/>
          </Box>
          <Box className="top-section-right">
            <Box className="upper">
            <Typography variant="h5" component="div" className="heading">
            {details?.heading}{details?.company && ` at ${details?.company}`}
          </Typography>
            </Box>
            <Box className="lower">
            {(details?.startDate  || details?.endDate) ? <Typography variant="h6" className="sub-heading" gutterBottom>
            {details?.startDate} to {details?.endDate}
            </Typography> : <Typography variant="h6" className="sub-heading">{""}</Typography>}
            </Box>
          </Box>
          <Box>
            <Button className="add-button" variant="contained" size="medium" onClick={()=>handleSave()}>Save</Button>
            <Button className="cancel-button" variant="outlined" size="medium" sx={{marginLeft:"0.7rem",border: "1px solid #e2e8f0"}} onClick={()=>handleCancel()}>Cancel</Button>
          </Box>
        </Box>

        {/* {details?.aboutText && <Box className="middle-section">
            <Box className="about-section">
                <Typography variant="body1" component="div" className="about-text ui-text-3-color">
                {details?.aboutText}
                </Typography>
            </Box>
        </Box>} */}

      </CardContent>
      {/* <CardActions className="bottom-section">
        <Box className="action-container">
            <Box className="edit-button" onClick={handleEditClick}><EditOutlinedIcon className="edit-icon"/></Box>
            <Box className="delete-button" onClick={handleDeleteClick}><DeleteOutlineOutlinedIcon className="delete-icon"/></Box>
        </Box>
      </CardActions> */}
    </Card>
    </Box>
  )
}

export default CardEditHeader;