import { Box, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StateProps } from "../../../../redux/reducer";
import { CourseType } from "../../../../components/utils";
import { setExtractedData } from "../../../../redux/actions";
import CourseEdit from "./CourseEdit";
import CourseCard from "./CourseCard";

interface CoursesListProps {
  handleSectionDelete:(section:string) =>void;
  sectionName:string;
}

const DEFAULT_FORM_DATA:CourseType = {
  organization: "",
  startDate:"",
  endDate:"",
  courseDescription:"",
  isEdit:true, 
  isNewEntry:true,  
};


const CoursesList:React.FC<CoursesListProps> = ({handleSectionDelete,sectionName}) => {

  const extractedData = useSelector((state:StateProps) => state.extractedData);
  const dispatch = useDispatch();
  const [coursesList,setCoursesList] = useState<CourseType[]>(extractedData?.courses ?? []);
  const [draggingItemIndex, setDraggingItemIndex] = useState<number|null>(null);


  //Adding key to extrated course elements
  useEffect(()=>{
    if(!extractedData?.courses?.[0]) {
      setCoursesList([{...DEFAULT_FORM_DATA,index:0}])
    }
    else {
      const updatedList = extractedData?.courses?.map((course,i) =>{
        return {...course,isEdit:false,index:i}
      });
      setCoursesList(updatedList ?? []);
    }
  },[extractedData?.courses]);

  const handleAddCourse = () => {
    //Adding new course entry as form at the end
    const updateCourseList = coursesList?.map((course)=>({...course,isEdit:false}));
    setCoursesList([...updateCourseList,{...DEFAULT_FORM_DATA,index:coursesList?.length ?? 1}])
  }

  const handleDragStart = (index:number) => {
    setDraggingItemIndex(index);
  }

  const handleDragOver = (e:any,index:number) => {
    e.preventDefault();
  }

const handleDrop = (index:number) => {
  const draggedOverItem = extractedData?.courses?.[index];

  // If the item is dragged over itself, don't change the list
  if (draggingItemIndex === index) {
    return;
  }

  if(draggedOverItem && (draggingItemIndex || draggingItemIndex===0) && extractedData?.courses?.[draggingItemIndex]){
    const updatedItems = [...extractedData?.courses];

    const temp = updatedItems[index];
    updatedItems[index] = updatedItems[draggingItemIndex];
    updatedItems[draggingItemIndex] = temp;

          dispatch(setExtractedData({
            ...extractedData,
            courses:updatedItems,
          }));
      setDraggingItemIndex(index);
      }
    };


const handleDragEnd = () => {
  setDraggingItemIndex(null);
}


   return (
     <Box className="courses-section">
            <Box className="heading-box">
            <Typography variant="h5" color="color.primary" className="heading">
              Courses
            </Typography>
            <Typography className="sub-heading"
                         onClick={()=>{
                          handleSectionDelete(sectionName)
                          }}
                          >Remove section</Typography>
          </Box>

    {coursesList?.map((course,index)=>(<ListItem className="card-wrapper" 
     draggable={course?.isEdit ? false :true}
     onDragStart={()=>handleDragStart(index)}
     onDragOver={(e)=>handleDragOver(e,index)}
     onDrop={()=>handleDrop(index)}
     onDragEnd={handleDragEnd}
     sx={draggingItemIndex === index ? {
      transition:"transform 0.3s ease",
      opacity: "0.7",
      transform: "scale(1.05)",
      cursor: course?.isEdit ? "none" : "grab",
     } : {}}
     >
      {course?.isEdit ? <CourseEdit courseData={course}
       coursesList={coursesList} 
       setCoursesList={setCoursesList}
       index={index}
       />
       : <CourseCard courseData={course}
        coursesList={coursesList}
         setCoursesList={setCoursesList}
         />}
      </ListItem>))}

    {!(coursesList?.[coursesList?.length - 1]?.isNewEntry) && <Box className="add-course-button-cont">
     <Typography className="summary-ui-brand" onClick={handleAddCourse}>Add Course</Typography>
    </Box>}
    
    </Box>
   )
}

export default CoursesList;