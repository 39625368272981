import { IResume } from '../components/utils';
import { CLOSE_POPOVER, LOGOUT_USER, OPEN_POPOVER, SET_ACCESS_TOKEN, SET_ACTIVE_ATS_CHECK_RESULT_ID, SET_COLOR_DATA, SET_EXTRACTED_DATA, SET_FIXED_SECTIONS, SET_LOGGED_IN_USER_DATA, SET_RESUME_HEIGHT, SET_SELECTED_TEMPLATE_ID, SET_SIDEDRAWER_STATE, UPDATE_POPOVER_VALUE } from './actionTypes';

export interface StateProps {
  extractedData: IResume;
  color: string;
  sideDrawer: boolean;
  popover: {
    anchorEl: HTMLElement | null;
    open: boolean;
    section: string;
    sectionValue: string;
    sectionIndex: number;
  };
  fixedSections: any;
  previewResumeData:{
    resumeHeight:null,
    multiplicationFactor:number,
  };
  activeATSCheckResultID: string | null; // Add this line
}

const initialState: StateProps = {
  extractedData: {
    name: {
      firstName: "",
      lastName: ""
    },
    profile_Picture: null,
    email: "",
    phone: [],
    location: "",
    socialLinks: {
      personal_Website: "",
      linkedIn_URL: "",
      github_URL: "",
      twitter_X_URL: null
    },
    careerObjective: "",
    designation: "",
    educationalDetails: [],
    workExperiences: [],
    projects: [],
    awards: [],
    references: [],
    volunteerWorks: [],
    extraCA: [],
    customSections: {},
    certifications: "",
    toolsTechnicalSkills: [],
    nonTechnicalSkills:[],
    programmingLanguages: "",
    interests: "",
    tailoredResumeObject: {},
  } as IResume,
  color: "default",
  sideDrawer: false,
  popover: {
    anchorEl: null,
    open: false,
    section: '',
    sectionValue: '',
    sectionIndex: -1
  },
  fixedSections: {},
  previewResumeData:{
    resumeHeight:null,
    multiplicationFactor:0.7,
  },
  activeATSCheckResultID: null,
};

const dataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SIDEDRAWER_STATE:
      return {
        ...state,
        sideDrawer: action.payload,
      };
    case SET_EXTRACTED_DATA:
      return {
        ...state,
        extractedData: action.payload,
      };
    case SET_COLOR_DATA:
      return {
        ...state,
        color: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_LOGGED_IN_USER_DATA:
      return {
        ...state,
        loggedInUserData: action.payload,
      };
    case SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loggedInUserData: null,
      };
    case OPEN_POPOVER:
      return {
        ...state,
        popover: {
          anchorEl: action.payload.anchorEl,
          open: true,
          section: action.payload.section,
          sectionValue: action.payload.sectionValue,
          sectionIndex: action.payload.sectionIndex
        }
      };
    case CLOSE_POPOVER:
      return {
        ...state,
        popover: {
          anchorEl: null,
          open: false,
          section: '',
          sectionValue: '',
          sectionIndex: null
        }
      };
    case UPDATE_POPOVER_VALUE:
      return {
        ...state,
        popover: {
          ...state.popover,
          sectionValue: action.payload
        }
      };
    case SET_FIXED_SECTIONS:
      return {
        ...state,
        fixedSections: action.payload,
      };
    case SET_RESUME_HEIGHT:
      return {
        ...state,
        previewResumeData:action.payload,
      }
    case SET_ACTIVE_ATS_CHECK_RESULT_ID:
      return {
        ...state,
        activeATSCheckResultID: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;