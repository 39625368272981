import { useState, useEffect, RefObject } from "react";

export function usePickerPopperOffset(containerRef: RefObject<HTMLDivElement>): [number, number] {
  const [offset, setOffset] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      setOffset([containerWidth, 0]); // Adjust vertical offset as needed
    }
  }, [containerRef]);
  
  return offset;
}