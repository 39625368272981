import { Box, Button, List, ListItem, Modal, Typography } from "@mui/material";
import { summarSectionModalstyle } from "../Summary";
import ReactQuill from "react-quill";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {ReactComponent as AiIcon} from "../../../assets/SVG/ai-description-icon.svg";
import {ReactComponent as RegenerateIcon} from "../../../assets/SVG/referesh-icon.svg";
import {ReactComponent as AddAiIcon} from "../../../assets/SVG/add-ai-suggestion.svg";
import {ReactComponent as DoneAiIcon} from "../../../assets/SVG/done-ai-suggestion.svg";
import cn from 'classnames';
import { useSelector } from "react-redux";
import { IResume } from "../../../components/utils";
import { cleanSummaryHTMLstring } from "../../../components/common/CommonFunctions";
import { quillModules } from "../../../App";


interface ProfessionalSummaryModalProps {
    professionalModal:boolean;
    setProfessionalModal:Dispatch<SetStateAction<boolean>>;
    summary:string;
    setSummary:Dispatch<SetStateAction<string>>;
}


const ProfessionalSummaryModal:React.FC<ProfessionalSummaryModalProps> = ({professionalModal,setProfessionalModal,summary,setSummary}) => {
    const extractedData = useSelector((state:IResume) => state.extractedData);
    const [modalSummary,setModalSummary] = useState("");
    const [isCursorActive, setIsCursorActive] = useState(false);
    const [aiList,setAiList] = useState<{isAdded:boolean,text:string}[]>([
        { isAdded: false, text: "Highly motivated professional with extensive experience in delivering successful projects on time and within budget." },
        { isAdded: false, text: "Dedicated team player with proven leadership skills and a track record of driving business growth." },
        { isAdded: false, text: "Detail-oriented individual with a strong background in data analysis and problem-solving." },
        { isAdded: false, text: "Seeking a challenging position in a dynamic organization where I can apply my skills and grow professionally." },
        { isAdded: false, text: "Aiming to leverage my expertise in software development to contribute to innovative projects." },
        { isAdded: false, text: "Aspiring to secure a role in project management where I can utilize my organizational and leadership skills." },
        { isAdded: false, text: "Experienced in full-stack web development, specializing in React and Node.js." },
        { isAdded: false, text: "Proficient in cloud computing with a focus on AWS and DevOps best practices." },
        { isAdded: false, text: "Skilled in data science and machine learning, with hands-on experience in Python and R." },
        { isAdded: false, text: "Collaborated effectively with cross-functional teams to achieve project goals and enhance team productivity." },
        { isAdded: false, text: "Led a team of developers to deliver high-quality software solutions within tight deadlines." },
        { isAdded: false, text: "Proven ability to manage diverse teams and foster a collaborative working environment." },
        { isAdded: false, text: "Successfully reduced operational costs by 15% through process improvements and automation." },
        { isAdded: false, text: "Increased customer satisfaction scores by implementing user-centric design practices." },
        { isAdded: false, text: "Developed and deployed a scalable application that handled over 1 million users." }
      ])

    useEffect(()=>{
        if(professionalModal) {
            const getSummaryAiData = async () => {
                try {
                  const response = await fetch(`https://be.tekno.ai/get-help-with-writing`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({sectionToGenerate:"professionalSummary",resumeObject:extractedData, }),
                  });
            
                  if (response.ok) {
                    const data = await response.json();
                    const formattedAiList = data?.response?.map((desc:any)=>({isAdded:false,text:desc?.value}));
                    // setAiList(formattedAiList);
                  } else if (response.status === 401) {
                    console.error('Login failed: Invalid credentials');
                  } else {
                    const errorData = await response.json();
                    console.error('Login failed:', errorData.message);
                  }
                } catch (error) {
                  console.error('Error during login:', error);
                }
              };
              getSummaryAiData();
        }
    },[professionalModal])

     /**
     * Below useEffect is to set the modalsummary value initially
    */
     useEffect(()=>{
        setIsCursorActive(false);
        if(summary && professionalModal) {
            const cleansummary = cleanSummaryHTMLstring(summary);
            setModalSummary(cleansummary);
        }
    },[professionalModal]);

    const handleConfirmAndContinue = () => {
        const cleansummary = cleanSummaryHTMLstring(modalSummary);  
        setSummary(cleansummary);
        setProfessionalModal(false);
    }

    const handleChange = (e:any) => {
        /**
         * Checking whether Ai Added modalSummary Changed or not
         * This handle chnage runs every time when there is a change in react quill state
        */
       if(isCursorActive){
        const updatedAiList = aiList.map((listItem)=>{
            if(listItem.isAdded === true && !e.includes(listItem.text)){
                return {...listItem, isAdded: false}
            }
            else if(listItem.isAdded === false && e.includes(listItem.text)){
                return {...listItem, isAdded: true}
            }
            else {
                return listItem;
            }
           })
           setAiList(updatedAiList);
           setModalSummary(e);
       }
    //    else {
    //     const cleansummary = cleanSummaryHTMLstring(e);
    //     setModalSummary(cleansummary);
    //    }
    }

    const handleTextClick = (text:string) => {
        if(!text) return;
        const upDatedList = aiList?.map((listItem)=> {
            if(listItem.text === text){
                return {...listItem, isAdded: true};
            }
            return listItem;
        })
        setAiList(upDatedList);
        const cleansummary = cleanSummaryHTMLstring(modalSummary)
        setModalSummary(cleansummary+`<ul><li>${text}</li></ul>`);
    }

      // Handle focus event
        const handleFocus = () => {
            setIsCursorActive(true);
        };

        // Handle blur event
        const handleBlur = () => {
            setIsCursorActive(false);
        };

    return (
        <Modal
        open={professionalModal}
        className="professional-summary-modal-container"
        onClose={()=>setProfessionalModal(false)}
        >
            <Box sx={summarSectionModalstyle} className="modal-wrapper">
                <Box className="modal-heading">
                    <Typography className="heading-text" variant="h5" component="h5">
                        Professional Summary
                    </Typography>
                </Box>

                <Box
                sx={{    
                    display:"flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding:2,
                    }}
                    className="modal-content-wrapper"
                >
                    <Box className="quill-container"> 
                    <Typography className="quill-heading">Description</Typography>
                    <ReactQuill
                        // ref={careerObjectiveInputRef}
                        placeholder="Add the relevant description."
                        value={modalSummary}
                        onChange={handleChange}
                        theme="snow" 
                        modules={quillModules}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        />
                    </Box>

                    <Box className="ai-suggestion-container">
                        <Box className="ai-title">
                            <Box className="ai-box">
                            <AiIcon/>
                            <Typography className="ai-description">AI Generated Description</Typography>
                            </Box>
                         <Box
                            sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: 2,
                            border: '1px solid #5594ff',
                            borderRadius:"100px",
                            cursor: 'pointer',
                            color: "#5594ff",
                            px: 1,
                            }}
                        // onClick={handleClick}
                        className="regenrate-wrapper"
                    >
                        <RegenerateIcon/> <Typography className="button" variant="caption">Regenrate</Typography>
                    </Box>
                        </Box>

                        <Box className="ai-content-container">
                            <List className="list">
                            {aiList?.map((sug)=>(
                                <ListItem className="list-item">
                                    {sug.isAdded ? 
                                     <Box className="item-wrapper active-item-wrapper">
                                    <Box className="icon-wrapper">
                                     <DoneAiIcon/>
                                    </Box>
                                    <Typography className="active-text">{sug.text}</Typography>
                                        </Box>
                                        :
                                        <Box className="item-wrapper" onClick={()=>handleTextClick(sug.text)}>
                                        <Box className="icon-wrapper">
                                         <AddAiIcon/>
                                        </Box>
                                        <Typography className={"inactive-text"}>{sug.text}</Typography>
                                    </Box>
                                    }

                                </ListItem>
                            ))}
                            </List>
                           
                        </Box>
                    </Box>
                </Box>

                <Box className="modal-footer">
                        <Button variant="outlined" sx={{border:"none","&:hover":{border:"none"}}} onClick={()=>setProfessionalModal(false)}>Cancel</Button>
                        <Button variant="contained" onClick={handleConfirmAndContinue}>Confirm and Continue</Button>
                </Box>
            </Box>  
        </Modal>
    )
}
export default ProfessionalSummaryModal;