import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Link as MuiLink,
  Grid,
  Avatar,
  CssBaseline,
  Link,
  Divider,
  Snackbar,
  Alert,
  Stepper,
  StepLabel,
  Step,
  InputAdornment,
  Autocomplete,
} from '@mui/material';
import { LinkedIn, Google, LockOutlined, ChevronRight } from '@mui/icons-material';
import { EmailIcon, GoogleIcon, LinkedInIcon, LockIcon, ProfileIcon } from '../Icons';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import {ReactComponent as ResumeBuilderIcon} from "../../assets/SVG/resume-builder-icon.svg";
import {ReactComponent as TeknoLogo} from "../../assets/SVG/logo.svg";
import loginBg from "../../assets/SVG/login-bg.png";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { SecondaryButton } from '../button/Buttons';
import { SnackBarState } from './Login';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken } from '../../redux/actions';
import { countries } from '../helpers/countries';
import LoginPageBg1 from '../../assets/SVG/LoginPageBg1.svg';
import LoginPageBg2 from '../../assets/SVG/LoginPageBg2.svg';

const steps = ['Basic Info', 'Password'];

const Login = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    password: "",
    confirmPassword: ""
  });

  const [selectedCountry, setSelectedCountry] = useState({ label: '', flag: '' });
  const [errors, setErrors] = useState<any>({});

  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [snackBar, setSnackBar] = useState<SnackBarState>({open: false, message: "", severity: "success"});

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const accessToken = useSelector((state:any) => state.accessToken);

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      /* const encryptedPassword = CryptoJS.AES.encrypt(formData.password, "1234567890123456").toString(); */

      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, /* password: encryptedPassword */ }),
      });

      if (response.ok) {
        navigate('/login', { state: { fromRegistration: true } });
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Registration failed. Please try again.');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setErrorMessage('An error occurred. Please try again later.');
      setOpen(true);
    }
  };

  const handleClose = (event: any) => {
    setOpen(false);
  };

  const handleCountryChange = (event: React.ChangeEvent<{}>, value: { label: string, flag: string } | null) => {
    setFormData({ ...formData, country: value ? value.label : '' });
    setSelectedCountry(value || { label: '', flag: '' });
    validateField('country', value ? value.label : '');
  };

  const validateField = (name: string, value: string) => {
    let error = '';
    switch (name) {
      case 'firstName':
        if (!value) error = 'First Name is required';
        break;
      case 'lastName':
        if (!value) error = 'Last Name is required';
        break;
      case 'email':
        if (!value) error = 'Email is required';
        break;
      case 'phoneNumber':
        if (!value) error = 'Phone Number is required';
        break;
      case 'country':
        if (!value) error = 'Country is required';
        break;
      case 'password':
        if (!value) error = 'Password is required';
        break;
      case 'confirmPassword':
        if (!value) error = 'Confirm Password is required';
        if (value !== formData.password) error = 'Passwords do not match';
        break;
      default:
        break;
    }
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: error }));
    return error === '';
  };

  const validateStep = (step: number) => {
    const fieldsToValidate = step === 0
      ? ['firstName', 'lastName', 'email', 'phoneNumber', 'country']
      : ['password', 'confirmPassword'];

    const newErrors: any = {};
    let isValid = true;

    fieldsToValidate.forEach((field) => {
      if (!validateField(field, formData[field as keyof typeof formData])) {
        isValid = false;
        newErrors[field] = errors[field];
      }
    });

    setErrors(newErrors);
    return isValid;
  };


  const handleNext = () => {
    const fieldsToValidate = activeStep === 0
      ? ['firstName', 'lastName', 'email', 'phoneNumber', 'country']
      : ['password', 'confirmPassword'];
  
    const newErrors: any = {};
    let isValid = true;
  
    fieldsToValidate.forEach((field) => {
      const value = formData[field as keyof typeof formData];
      if (!validateField(field, value)) {
        isValid = false;
        newErrors[field] = errors[field];
      }
    });
  
    setErrors(newErrors);
  
    if (!isValid) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSocialLogin = async (provider: string) => {
    try {
      debugger
      const response = await fetch(`${process.env.REACT_APP_API_URL}/authorize?provider=${provider}`, { method: 'GET' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      window.location.href = data.authorizeUrl+'&provider='+provider;
  
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  useEffect(() => {
    if (location.state?.fromRegistration) {
      setSnackBar({
        open: true,
        severity: "success",
        message: 'Registration successful. Please log in.'
      });
    }
    if (location.search) {
      const signInCallback = async () => {
        const queryParams = new URLSearchParams(location.search);
        const provider = location.search && location.search.includes("googleapis") ? "Google" : "LinkedIn";
        const code = queryParams.get('code');
        const state = queryParams.get('state');

        if (code && state /* && !accessToken */) {
          try {
            // Adjust the API endpoint or parameters as needed
            const response = await fetch(`${process.env.REACT_APP_API_URL}/signin-callback?provider=${provider}&code=${code}&state=${state}`, { method: 'GET' });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const accessToken = await response.json();
            dispatch(setAccessToken(accessToken));
            navigate('/', { state: { isLoginSuccessful: true } });
          } catch (error) {
            console.error('There was an error!', error);
          }
        }
        console.log("Access Token: ", accessToken);
      };
      signInCallback();
    };
  }, [location]);

  const CustomConnector = () => {
    return (
      <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <ChevronRight sx={{fontSize:"1rem",color:"grey"}}/>
      </Box>
    )
  }

  return (
    <Grid container direction={"row"} justifyContent={"center"} style={{ display: "flex", alignItems: "center" }}>

      <Grid
        item
        md={6}
        sx={{
          background: "linear-gradient(45deg, #0045B9 0%, #3E70FF 100%)",
          height: '100vh',
          backgroundSize: '100% 100%',
          display: { xs: "none", md: "flex" },
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src={LoginPageBg1}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <Box
          component="img"
          src={LoginPageBg2}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 28
          }}
        />
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#E8EEFF' }}>
          100% Free Resume Builder
        </Typography>
        <Box mt={2}>
          <ResumeBuilderIcon
            style={{ width: '100%', maxWidth: '300px' }}
          />
        </Box>
        <Box mt={2}>
          <ul style={{ color: '#C5D6FF', textAlign: "left" }}>
            <li>Better Resume. Faster Search. More Offers.</li>
            <li>100+ Templates to choose from.</li>
            <li>AI Integration.</li>
            <li>ATS-friendly.</li>
            <li>Tailor your resume for each job application.</li>
          </ul>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Container component="div" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CssBaseline />
            <Grid
              item
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding:"1rem",
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 1, gap: 1 }}>
                <TeknoLogo />
                <Typography component="h5" variant="h5" sx={{ fontWeight: "bolder" }}>
                  Tekno.ai
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem' }}>
                  Register for a new account
                </Typography>
              </Box>
          <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: '100%' }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          <CssBaseline />
          
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Stepper activeStep={activeStep} connector={<CustomConnector/>}>
              {steps.map((label, index) => (
                <Step key={label} onClick={() => setActiveStep(steps.indexOf(label))} sx={{ cursor: "pointer" }}>
                  <StepLabel
                    StepIconProps={{
                      sx: {
                        border: `1px solid ${activeStep === index ? '#1976d2' : '#CDD0D5'}`,
                        borderRadius: '50%',
                        backgroundColor: 'transparent',
                        fill: "none",
                        color: activeStep === index ? '#1976d2' : '#CDD0D5',
                        '& .MuiStepIcon-text': {
                          fill: activeStep === index ? '#1976d2' : '#CDD0D5',
                        },
                        '& .MuiStepIcon-root': {
                          border: `1px solid ${activeStep === index ? '#1976d2' : '#CDD0D5'}`,
                          borderRadius: '50%',
                          backgroundColor: 'transparent',
                        },
                        '&.Mui-completed': {
                          fill: "currentcolor",
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box>
              <Box component="form" onSubmit={submitForm} sx={{ mt: 3, mb: 3 }}>
                <Grid container spacing={2}>
                {activeStep === 0 && (
                  <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" component="label" htmlFor="firstName" gutterBottom sx={{ fontWeight: "bold" }}>First Name *</Typography>
                    <TextField
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      placeholder="Enter your First Name"
                      autoFocus
                      onChange={(e) => {
                        setFormData({ ...formData, firstName: e.target.value });
                        validateField('firstName', e.target.value);
                      }}
                      sx={AuthInputFieldStyle}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ProfileIcon />
                          </InputAdornment>
                        )
                      }}





                      error={!!errors.firstName}
                      helperText={errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" component="label" htmlFor="lastName" gutterBottom sx={{ fontWeight: "bold" }}>Last Name *</Typography>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      placeholder="Enter your Last Name"
                      name="lastName"
                      onChange={(e) => {
                        setFormData({ ...formData, lastName: e.target.value });
                        validateField('lastName', e.target.value);
                      }}
                      sx={AuthInputFieldStyle}
                      inputProps={{
                        sx: {
                          height: "1rem"
                        }
                      }}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" component="label" htmlFor="email" gutterBottom sx={{ fontWeight: "bold" }}>Email *</Typography>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      placeholder="Enter your Email"
                      name="email"
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value });
                        validateField('email', e.target.value);
                      }}
                      sx={AuthInputFieldStyle}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        )
                      }}
                      inputProps={{
                        sx: {
                          height: "1rem"
                        }
                      }}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" component="label" htmlFor="phone" gutterBottom sx={{ fontWeight: "bold" }}>Phone *</Typography>
                    <TextField
                      required
                      fullWidth
                      name="phone"
                      placeholder="Enter your Phone Number"
                      type="number"
                      id="phone"
                      onChange={(e) => {
                        setFormData({ ...formData, phoneNumber: e.target.value });
                        validateField('phoneNumber', e.target.value);
                      }}
                      sx={AuthInputFieldStyle}
                      inputProps={{
                        sx: {
                          height: "1rem"
                        }
                      }}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" component="label" htmlFor="country" gutterBottom sx={{ fontWeight: "bold" }}>Country *</Typography>
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
                          <img
                            loading="lazy"
                            width="20"
                            src={option.flag}
                            alt=""
                            style={{ marginRight: '10px' }}
                          />
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select your Country"
                          fullWidth
                          required
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: selectedCountry.label ? (
                              <img
                                loading="lazy"
                                width="20"
                                src={selectedCountry.flag}
                                alt=""
                                style={{ marginRight: '10px' }}
                                />
                              ) : null,
                            }}
                          error={!!errors.country}
                          helperText={errors.country}
                        />
                      )}
                      onChange={handleCountryChange}
                    />
                  </Grid>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="label" htmlFor="password" gutterBottom sx={{ fontWeight: "bold" }}>Password *</Typography>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        placeholder="Enter your Password"
                        type="password"
                        id="password"
                        onChange={(e) => {
                          setFormData({ ...formData, password: e.target.value });
                          validateField('password', e.target.value);
                        }}
                        sx={AuthInputFieldStyle}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          )
                        }}
                        error={!!errors.password}
                        helperText={errors.password}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="label" htmlFor="confirmPassword" gutterBottom sx={{ fontWeight: "bold" }}>Confirm Password *</Typography>
                      <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        placeholder="Confirm your Password"
                        type="password"
                        id="confirmPassword"
                        sx={AuthInputFieldStyle}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => validateField('confirmPassword', e.target.value)}
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword}
                      />
                    </Grid>
                  </>
                )}
                {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}
                </Box> */}
                  <Grid item xs={12}>
                    <SecondaryButton
                      fullWidth
                      variant="contained"
                      size="large"
                      type={activeStep === steps.length - 1 ? 'submit' : 'button'}
                      onClick={activeStep === steps.length - 1 ? undefined : handleNext}
                    >
                      {activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
                    </SecondaryButton>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Already have an account?&nbsp;
                <Typography component={RouterLink} to="/login" sx={{ fontWeight: 'bold' }}>
                  Login
                </Typography>
              </Box>
              <Box mb={3} sx={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ flex: 1, height: '1px', backgroundColor: '#E2E4E9', mr: 2 }} />
                <Typography variant="body1" color="#868C98">
                  Login with
                </Typography>
                <Box sx={{ flex: 1, height: '1px', backgroundColor: '#E2E4E9', ml: 2 }} />
              </Box>
              <Box mb={3} sx={{ display: 'flex', width: "100%", justifyContent: 'space-between', gap: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  className="social-login-button"
                  style={{ backgroundColor: '#fff', color: '#000', textTransform: "none", borderRadius: "10px", padding: "10px", boxShadow: "0px 1px 2px 0px rgba(228, 229, 231, 0.24)", border: "1px solid #E2E4E9"}}
                  startIcon={<LinkedInIcon />}
                  onClick={() => handleSocialLogin("LinkedIn")}
                >
                  LinkedIn
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  className="social-login-button"
                  style={{ backgroundColor: '#fff', color: '#000', textTransform: "none", borderRadius: "10px", padding: "10px", boxShadow: "0px 1px 2px 0px rgba(228, 229, 231, 0.24)", border: "1px solid #E2E4E9"}}
                  startIcon={<GoogleIcon />}
                  onClick={() => handleSocialLogin("Google")}
                >
                  Google
                </Button>
              </Box>
            </Box>
          </Box>
          </Grid>
          </Container>
      </Grid>
    </Grid>

  );
}

export default Login;