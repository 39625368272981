import React, { useLayoutEffect, useRef, useState } from 'react';

import styles from './Template1.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { isValueExist } from '../components/utils';
import defaultProfilePicture from '../assets/profile_picture.png';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { closePopover, openPopover, setExtractedData, setFixedSections, setPreviewResumeData, updatePopoverValue } from '../redux/actions';
import { useRefContext } from './TemplateSectionsRef';
import { StateProps } from '../redux/reducer';
import ListTypeSkills from '../components/CommonComponents/ListTypesSkills';
import SummarySectionDetailsList from '../components/CommonComponents/SummarySectionDetails';
import { getCustomDataObject } from '../components/common/CommonFunctions';

const ResumePreview = (props: any) => {
  const previewResumeData = useSelector((state:StateProps)=> state?.previewResumeData)
    const { height = "100%", improvableSections = {} } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const extractedData = useSelector((state: any) => state.extractedData);
    let resumeDetails = props.resumeObject ? props.resumeObject : extractedData;

    //TODO: Remove it after debugging
    resumeDetails = {
        "name": {
            "firstName": "CHARLES",
            "lastName": "MCTURLAND"
        },
        "profilePicture": null,
        "email": "cmcturland@email.com",
        "phone": [
            "(123) 456-7890"
        ],
        "location": "New York, NY",
        "socialLinks": {
            "personal_Website": "NA",
            "linkedIn_URL": "NA",
            "github_URL": "NA",
            "twitter_X_URL": null
        },
        "careerObjective": "NA",
        "designation": "SOFTWARE ENGINEER",
        "educationalDetails": [
            {
                "universityCollege": "University of Pittsburgh",
                "degree": "B.S.",
                "location": null,
                "isEdit": null,
                "isNewEntry": null,
                "index": "f1592529-220a-4d09-9534-456e1f762fd9",
                "specialization": "Computer Science",
                "gpa": "NA",
                "startDate": "September 2008",
                "endDate": "April 2012"
            }
        ],
        "workExperiences": [
            {
                "startDate": "January 2015",
                "endDate": "current",
                "company": "Embark",
                "jobType": null,
                "role": "Software Engineer",
                "location": null,
                "isEdit": null,
                "isNewEntry": null,
                "index": "0d7fd69f-393c-4310-8830-778f583715ec",
                "keyResponsibilities": "Worked with product managers to re-architect a multi-page web app into a single page web-app, boosting yearly revenue by $1.4M\nConstructed the logic for a streamlined ad-serving platform that scaled to our 35M users, which improved the page speed by 15% after implementation\nT ested software for bugs and operating speed, ﬁxing bugs and documenting processes to increase efﬁciency by 18%\nIterated platform for college admissions, collaborating with a group of 4 engineers to create features across the software",
                "projectDetails": "NA",
                "toolsTechnologies": "NA"
            },
            {
                "startDate": "April 2012",
                "endDate": "January 2015",
                "company": "MarketSmart",
                "jobType": null,
                "role": "Software Engineer",
                "location": null,
                "isEdit": null,
                "isNewEntry": null,
                "index": "c0402a77-2e35-4b8d-8728-708393c1d3c4",
                "keyResponsibilities": "Built RESTful APIs that served data to the JavaScript front-end based on dynamically chosen user inputs that handled over 500,000 concurrent users\nBuilt internal tool using NodeJS and Pupeteer.js to automate QA and monitoring of donor-facing web app, which improved CTR by 3%\nReviewed code and conducted testing for 3 additional features on donor-facing web app that increased contributions by 12%",
                "projectDetails": "NA",
                "toolsTechnologies": "NA"
            },
            {
                "startDate": "April 2011",
                "endDate": "March 2012",
                "company": "Marketing Science Company",
                "jobType": null,
                "role": "Software Engineer Intern",
                "location": null,
                "isEdit": null,
                "isNewEntry": null,
                "index": "17e5d29a-306d-4fc7-a7ca-91ff79e29ab0",
                "keyResponsibilities": "Partnered with a developer to implement RESTful APIs in Django, enabling analytics team to increase reporting speed by 24%\nUsing Selenium I built out a unit testing infrastructure for a client application that reduced the number of bugs reported by the client by 11% month over month",
                "projectDetails": "NA",
                "toolsTechnologies": "NA"
            }
        ],
        "projects": [
            {
                "title": "Poker Simulation",
                "description": "Built a full-stack web app to allow users to simulate and visualize outcomes of poker hands against opponents of different play styles using open source cards.js on the front-end\nUtilized sci-kit learn in Python to simulate possible outcomes under different scenarios that the user chose",
                "toolsTechnologies": "NA"
            }
        ],
        "courses": null,
        "certifications": "NA",
        "toolsTechnicalSkills": "Python (Django)\nJavascript (NodeJS ReactJS, jQuery)\nSQL (MySQL, PostgreSQL, NoSQL)\nHTML5/CSS\nAWS\nUnix, Git",
        "programmingLanguages": "Python, Javascript, SQL",
        "interests": "NA",
        "languages": [],
        "hobbies": [],
        "tailoredResumeObject": null
    };
    const {anchorEl, open, section, sectionValue, sectionIndex} = useSelector((state: any) => state.popover);
    const fixedSections = useSelector((state: any) => state.fixedSections);
    const dispatch = useDispatch();

    const { registerRef } = useRefContext();

    const isImprovable = (section: string, index: number = -1) => {
        if (index !== -1) {
            return !(fixedSections[section] && (fixedSections[section] as { [index: number]: boolean })[index]) && improvableSections[section] && improvableSections[section][index];
        }
        return !(fixedSections[section] as boolean) && improvableSections.hasOwnProperty(section);
    };

    useLayoutEffect(()=>{
      if(containerRef?.current) {
        dispatch(setPreviewResumeData({...previewResumeData,resumeHeight:containerRef.current.offsetHeight * previewResumeData?.multiplicationFactor}));
        console.log(containerRef.current.offsetHeight,"Resume useLayoutEffect")
      }
    },[containerRef?.current?.offsetHeight,dispatch]);

    console.log({resumeDetails},"resume1 Component")

    return (
        <div style={{/* height:`calc((100vh - 10.15rem) * (1/${previewResumeData?.multiplicationFactor})`, */ ...props.wrapperStyle}} ref={containerRef}>
        <div className={styles.resume} style={{ /* height: height */ }} >
            <div id="profile-section" ref={registerRef('profile')} className={`${styles.profile} ${isImprovable('profile') ? `improvable` : ''}`}>
            <img src={resumeDetails?.profile_Picture ? resumeDetails?.profile_Picture : defaultProfilePicture} alt="Profile" />
            <h1>{resumeDetails?.name?.firstName} {resumeDetails?.name?.lastName}</h1>
            <h2>{resumeDetails?.designation}</h2>
            {resumeDetails?.email && <p>Email: {resumeDetails.email}</p>}
            {resumeDetails?.phone?.length > 0 && <p>Phone: {resumeDetails.phone.join(", ")}</p>}
            {resumeDetails?.location && <p>Address: {resumeDetails.location}</p>}
            {isValueExist(resumeDetails?.careerObjective) && <div id="career-objective-section" ref={registerRef('careerObjective')} className={`flex flex-col w-[100%] ${isImprovable('careerObjective') ? `improvable` : ''}`}>
                <p className="mt-4 text-left">Career Objective: </p>
                <p dangerouslySetInnerHTML={{__html:resumeDetails.careerObjective}}>
                {/* {resumeDetails.careerObjective} */}
                </p>
                {isImprovable('careerObjective') && (
                <IconButton onClick={(event) => dispatch(openPopover(event.currentTarget, 'careerObjective', improvableSections.careerObjective))} className="edit-icon">
                    <Edit />
                </IconButton>
                )}
            </div>}

            {isValueExist(resumeDetails?.languages?.[0]) && 
                <div className={"skills-section"}>
                <div className={"heading-container"}><Typography className="heading">Languages</Typography></div>  
                {typeof resumeDetails?.["languages"] === "string" ? <ListTypeSkills skillStyle={"skill"} skills={resumeDetails["languages"]?.split(",")}/> :<ListTypeSkills skillStyle={"skill"} skills={resumeDetails["languages"]}/>}
            </div>
            }

        {isValueExist(resumeDetails?.hobbies?.[0]) && 
            <div className={"skills-section"}>
            <div className={"heading-container"}><Typography className="heading">Hobbies</Typography></div>  
            {typeof resumeDetails?.["hobbies"] === "string" ? <ListTypeSkills skillStyle={"skill"} skills={resumeDetails["hobbies"]?.split(",")}/> :<ListTypeSkills skillStyle={"skill"} skills={resumeDetails["hobbies"]}/>}
        </div>
        }
            </div>

            <div>
            {isValueExist(resumeDetails?.toolsTechnicalSkills) && <div id="skills-section" ref={registerRef('toolsTechnicalSkills')} className={`${styles.section} ${isImprovable('toolsTechnicalSkills') ? `improvable` : ''}`}>
                <h2>Skills</h2>
                <div className={styles.skills}>
                {/* {(typeof resumeDetails.toolsTechnicalSkills === "string" ? resumeDetails.toolsTechnicalSkills.split("\n") : resumeDetails.toolsTechnicalSkills).map((skill: string, index: number) => (
                    <div className={styles.skill} key={index}>{skill}</div>
                ))} */}
                </div>
                {isImprovable('toolsTechnicalSkills') && (
                <IconButton onClick={(event) => dispatch(openPopover(event.currentTarget, 'toolsTechnicalSkills', improvableSections.toolsTechnicalSkills.split(",")))} className="edit-icon">
                    <Edit />
                </IconButton>
                )}
            </div>}
            {isValueExist(resumeDetails?.workExperiences) && <div id="work-experience-section" className={styles.section}>
                <h2>Experience</h2>
                {resumeDetails.workExperiences.map((workExperience: any, index: number) => (
                <div ref={registerRef(`workExperiences-${index}`)} className={`${styles.experience} ${isImprovable('workExperiences', index) ? `improvable` : ''}`} key={index}>
                    <h3>{workExperience.role} at {workExperience.company}</h3>
                    <p>{workExperience.startDate} - {workExperience.endDate}</p>
                    <ul>
                    {(typeof workExperience?.keyResponsibilities === "string" ? workExperience?.keyResponsibilities.split("\n") : workExperience.keyResponsibilities)?.map((resp: string, i: number) => (
                        <li key={i}>{resp}</li>
                    ))}
                    </ul>
                    {isImprovable('workExperiences', index) && (
                    <IconButton onClick={(event) => dispatch(openPopover(event.currentTarget, 'workExperiences', {...resumeDetails.workExperiences[index], ...improvableSections.workExperiences[index]}, index))} className="edit-icon">
                        <Edit />
                    </IconButton>
                    )}
                </div>
                ))}
            </div>}
            {isValueExist(resumeDetails?.educationalDetails) && <div id="education-section" className={styles.section}>
                <h2>Education</h2>
                {resumeDetails.educationalDetails.map((education: any, index: number) => (
                <div ref={registerRef(`educationalDetails-${index}`)} className={`${styles.experience} ${isImprovable('educationalDetails', index) ? `improvable` : ''}`} key={index}>
                    {isValueExist(education?.universityCollege) && <p>{education.universityCollege}</p>}
                    {isValueExist(education?.degree) && <h3>{education.degree} {isValueExist(education?.specialization) && `in ${education.specialization}`}</h3>}
                    {isValueExist(education?.gpa) && <p>GPA {education.gpa}</p>}
                    <p>{isValueExist(education?.startDate) && education.startDate} - {isValueExist(education?.endDate) && education.endDate}</p>
                    {isImprovable('educationalDetails', index) && (
                    <IconButton onClick={(event) => dispatch(openPopover(event.currentTarget, 'educationalDetails', {...resumeDetails.educationalDetails[index], ...improvableSections.educationalDetails[index]}, index))} className="edit-icon">
                        <Edit />
                    </IconButton>
                    )}
                </div>
                ))}
            </div>}

            {resumeDetails?.projects?.[0] && <SummarySectionDetailsList summaryDetails={resumeDetails?.projects} title={"Projects"}/>}
            {resumeDetails?.courses?.[0] && <SummarySectionDetailsList summaryDetails={resumeDetails?.courses} title={"Courses"}/>}
            {resumeDetails?.awards?.[0] && <SummarySectionDetailsList summaryDetails={resumeDetails?.awards} title={"Awards"}/>}
            {resumeDetails?.references?.[0] && <SummarySectionDetailsList summaryDetails={resumeDetails?.references} title={"References"}/>}
            {resumeDetails?.volunteerWorks?.[0] && <SummarySectionDetailsList summaryDetails={resumeDetails?.volunteerWorks} title={"Volunteer Works"}/>}
            {resumeDetails?.extraCA?.[0] && <SummarySectionDetailsList summaryDetails={resumeDetails?.extraCA} title={"Extracurricular Activities"}/>}
            {getCustomDataObject(resumeDetails?.customSections)?.[0] && 
            getCustomDataObject(resumeDetails?.customSections)?.map((customDataObject)=>(
              <SummarySectionDetailsList summaryDetails={customDataObject?.value} title={customDataObject?.label}/>
            ))
            }
            </div>
        </div>
        </div>
    );
};

ResumePreview.displayName = 'ResumePreview';
export default ResumePreview;
