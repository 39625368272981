import React, { useRef, useState } from 'react';
import { IoLocationOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { GrFormEdit } from "react-icons/gr";
import { getValidKeyValuePairs, isValueExist } from '../components/common/CommonFunctions';
import ListTypeSkills from './sections/ListTypesSkills';
import ListTypeDetails from './sections/ListTypeDetails';
import { useDispatch, useSelector } from 'react-redux';
import {ReactComponent as ProfilePictureIcon} from "../assets/SVG/default_profile.svg";
import {ReactComponent as UploadPictureIcon} from "../assets/SVG/upload_profile.svg";
import { setExtractedData } from '../redux/actions';
import ProfilePictureContainer from '../components/common/ProfilePictureContainer';
import EditProfilePictureModal from '../components/CommonComponents/EditProfilePictureModal';
import SocialLinks from '../components/common/SocialLinks';



const Template8:React.FC = React.forwardRef((props:any,ref:any) => {
  const resumeDetails = useSelector((state:any) => state.extractedData);
  const tempColor = useSelector((state:any) => state.color);
  const uploadPhotoRef = useRef<HTMLInputElement>(null);
  const [showEditProfilePicModal,setShowEditProfilePicModal] = useState<boolean>(false);
  const initalBase = useRef<any>();
  const socialLinks = getValidKeyValuePairs(resumeDetails?.socialLinks);

  const dispatch = useDispatch();

  console.log(resumeDetails,tempColor,resumeDetails,"from Template8")


  // const profileChangeHandler = () => {
  //   uploadPhotoRef?.current?.click();
  // }

  // const handleProfilePhotoUpload = (e:any):void => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       if (reader.result) {
  //         dispatch(setExtractedData({ ...resumeDetails, profile_Picture: reader.result.toString() }));
  //       } else {
  //         console.error('Failed to read file');
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   }
  //   }

    const setEditModalClose = () => {
      setShowEditProfilePicModal(false)
    };

    const profileImage = require("../assets/SVG/default_profile.svg")

   return (
    <>
    <div className={`template8-container pick-${tempColor}`} ref={ref}>
      <div className={"header-section-upper-border"}><div className={"header-section-upper-border__svg"}><svg height="18px" width="18px" viewBox="0 0 18 18">
                            <polygon points="0,0 0,18 18,0"></polygon>
                        </svg></div></div>
      <div className={'header-section'}>

       <ProfilePictureContainer setShowEditProfilePicModal={setShowEditProfilePicModal}/>
      <div className={'header-right-section'}>
      <div className={"header"}>
        <h1>{resumeDetails?.name?.firstName}{" "}{resumeDetails?.name?.lastName}</h1>
        {isValueExist(resumeDetails?.designation) && <h2>{resumeDetails?.designation}</h2>}
      </div>
      <div className={"contact-info"}>
      {resumeDetails?.Email && <p><span><MdOutlineMail/></span> {resumeDetails?.email}</p>}
        {resumeDetails?.Phone &&  
        <p><LuPhone/> {resumeDetails?.phone?.join(", ")}</p>}
        {resumeDetails?.["location"] && 
          <p><IoLocationOutline/> {resumeDetails?.["location"]}</p>}
          {socialLinks?.[0] && <SocialLinks links={socialLinks} iconColor={"grey"} iconSize={"1rem"}/>}  
      </div>
      </div> 
      </div>
       

      <div className={"main-content"}>
      {isValueExist(resumeDetails?.["careerObjective"]) &&  <div id="career_objective" className={"career-objective-section"}>
        <h2>Career Objective: </h2>
            <div>
              {resumeDetails["careerObjective"]}
            </div>
          </div>}

        <div className={"upper-border"}><div className={"heading-border"}></div></div>
        <div className={"skills-section"}>
         <div className={"heading-container"}><h2>Skills</h2><div className={"horizontal-line"}><div className={"line"}></div></div></div>  
          {typeof resumeDetails?.["toolsTechnicalSkills"] === "string" ? <ListTypeSkills skillStyle={"skill"} skills={resumeDetails["toolsTechnicalSkills"]?.split(",")}/> :<ListTypeSkills skillStyle={"skill"} skills={resumeDetails["toolsTechnicalSkills"]}/>}
        </div>

        <div className={"upper-border"}><div className={"heading-border"}></div></div>
        <div className={"experience-section"}>
        <div className={"heading-container"}><h2>Experience</h2><div className={"horizontal-line"}><div className={"line"}></div></div></div> 
          <ul className={"skill-container"}>
          {resumeDetails?.['workExperiences'] && resumeDetails?.['workExperiences'].map((experience: any, index: number) => (
            <li className={"experience"} key={index}>
              <h3>{experience.role} at {experience.company}</h3>
              <p>{experience?.["startDate"]} - {experience?.["endDate"]}</p>
              
                {isValueExist(resumeDetails?.['workExperiences']?.[index]?.["keyResponsibilities"]) && 
                (typeof experience["keyResponsibilities"] === "string" 
                ? <ListTypeDetails details={experience["keyResponsibilities"]?.split(",")}/>
                 : <ListTypeDetails details={experience["keyResponsibilities"]} />)}
               
                    {isValueExist(resumeDetails?.['Work Experiences']?.[index]?.["Project Details"]) &&
                     (typeof experience["projectDetails"] === "string"
                      ? <ListTypeDetails details={experience["PprojectDetails"]?.split(",")}/> 
                      : <ListTypeDetails details={experience["projectDetails"]} />)}
                  
            </li>
          ))}
          </ul>
        </div>

        <div className={"upper-border"}><div className={"heading-border"}></div></div>
        <div className={"education-section"}>
        <div className={"heading-container"}><h2>Education</h2><div className={"horizontal-line"}><div className={"line"}></div></div></div> 
          <ul className={"skill-container"}>
          {resumeDetails?.['educationalDetails'] && resumeDetails['educationalDetails'].map((education: any, index: number) => (
            <li className="education" key={index}>
              <h3>{education.degree} in {education.specialization}</h3>
              <p>{education["universityCollege"]}</p>
              <p>GPA {education?.gpa}</p>
              <p>{education?.["startDate"]} - {education?.["endDate"]}</p>
            </li>
          ))}
          </ul>
        </div>
      </div>
    </div>
     {showEditProfilePicModal && <EditProfilePictureModal base64ImageUrl={resumeDetails?.profile_Picture} onClose={setEditModalClose}/>}
    </>
    
   )
});

Template8.displayName = "Template8";

export default Template8;