
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import GitHubIcon from '@mui/icons-material/GitHub';
import LanguageIcon from '@mui/icons-material/Language';
import { Box, Typography } from '@mui/material';

export interface SocialType {
    "personal_Website":string;
    "linkedIn_URL":string,
    "github_URL":string,
    "twitter_X_URL":string,
}

type SocialIconType = Record<keyof SocialType,JSX.Element>


type SocialLink = {
    key: keyof SocialType,
    value: string
}

 interface SocialLinkProps {
    links:SocialLink[],
    iconColor: string,
    iconSize:string;
    horizontal?: boolean,
    dot?: boolean,
 }


const SocialLinks:React.FC<SocialLinkProps> = ({links,horizontal,iconColor,iconSize,dot}) => {
    const iconStyle = {
        fontSize:`${iconSize}`,color:`${iconColor}`,paddingRight:"0.2rem"
    }
    
    const socialIconObj:SocialIconType = {
            "personal_Website": <LanguageIcon sx={iconStyle}/>,
            "linkedIn_URL": <LinkedInIcon sx={iconStyle}/>,
            "github_URL": <GitHubIcon sx={iconStyle}/>,
            "twitter_X_URL": <XIcon sx={iconStyle}/>,
          };

    return <>{links?.[0] ? horizontal ? <Box sx={{display:"flex",justifyContent:"left",alignItems:"center"}} className="social-info">{links?.map((link,index)=>(
        <>
         {index!==0 && (dot ? <div className={'social-info__divider'}></div> : "|")}
         <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",fontSize:"1em"}}>
            <Typography>{socialIconObj?.[link?.key]}</Typography>
            <Typography className="text-content">{link.value}</Typography>
        </Box>
        </>

        ))}</Box> : <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"flex-start"}} className="social-info">{links?.map((link)=>(
    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",fontSize:"1em"}}>
        <Typography>{socialIconObj?.[link?.key]}</Typography>
        <Typography className="text-content">{link.value}</Typography>
    </Box>
    ))}</Box> : null}</>;
}
export default SocialLinks;