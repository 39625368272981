import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import {ReactComponent as CreateResumeIcon } from "./assets/SVG/user-landing-page-create-resume.svg";
import {ReactComponent as UploadResumeIcon} from "./assets/SVG/user-landing-page-upload-resume.svg";
import {ReactComponent as OnePageResumeIcon } from "./assets/SVG/user-landing-page-convert-to-single-page.svg";
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setExtractedData } from './redux/actions';
import Loading from './components/Loading/Loading';
import { Alert } from '@mui/material';

import AtsResumeCheckerPopup from './components/ATSChecker/AtsResumeCheckerPopup';
import { extractFaceFromPDF, WorkExperienceTypes } from './components/utils';
import SideDrawer from './ProcessResume/SideDrawer';
import AppBarHeader from './ProcessResume/AppBarHeader';
import { HandWaveIcon, Illustration_1 } from './components/Icons';
import HomeScreenTabs from './components/HomeScreenTabs/HomeScreenTabs';
import { seprateExtractedBulletPointsStringToArray } from './components/common/CommonFunctions';
import * as UploadingResume from "./assets/lottie/ATSCheckScreen.json";
import { getToken } from './components/auth/storeToken';
import { StateProps } from './redux/reducer';
// import { getDocument } from 'pdfjs-dist';
// const PDFJS = window.pdfjsLib;

interface userOption {
    title:string;
    subTitle:string;
    Icon:React.ReactNode;
    svgClass:string;
    link:string;
    onClick?: () => void;
}

const UserLandingPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [resumeActionType, setResumeActionType] = useState('');

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const extractedData = useSelector((state:StateProps)=> state?.extractedData);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        if (event.target.files?.length) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            console.log(process.env.REACT_APP_API_URL,"from API")

            try {
                const response = await fetch(`https://testapi.tekno.ai/api/extract-text`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const {textContent} = await response.json();
                    const requestData = { content: textContent } as any;
                    const endpointURL = resumeActionType === 'single-page-conversion' ? `https://be.tekno.ai/convert-resume-to-single-page` : `${process.env.REACT_APP_API_URL}/generate-content`;
                    const modelResponse = await fetch(endpointURL, {
                        method: 'POST',
                        body: JSON.stringify(requestData),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    const resumeObj = await modelResponse.json();

                    /**
                     * Converting pdf to image
                    */

                    const reader:any = new FileReader();
                    reader.onloadend = () => {
                        const base64string = reader.result.split(',')[1];
                        const extractedFace = extractFaceFromPDF(base64string);
                        if (extractedFace) {
                            resumeObj.profile_Picture = extractedFace;
                        }
                    };
                    reader.readAsDataURL(file);
                    console.log("resumeObj", resumeObj);
                    // const imgDataUrl = convertToImage(file);

                    //formatting resume object for projects section
                    let formattedResumeObject = resumeObj;
                    if(resumeObj?.projects?.[0]) {
                        const formattedProjects = resumeObj?.projects?.map((proj:any)=>({...proj,organization:proj?.title,courseDescription:proj?.description}));
                        formattedResumeObject = {...formattedResumeObject, projects: formattedProjects}
                    }
                     if(resumeObj?.workExperiences?.[0]) {
                        const formattedExperience:WorkExperienceTypes = resumeObj?.workExperiences?.map((exp:WorkExperienceTypes) => ({...exp,jobType:exp?.jobType ?? "fullTime",location:exp?.location ?? "",keyResponsibilities:seprateExtractedBulletPointsStringToArray(exp?.keyResponsibilities)}));
                        formattedResumeObject = {...formattedResumeObject, workExperiences: formattedExperience}
                    }
                    if(resumeObj?.toolsTechnicalSkills && typeof resumeObj.toolsTechnicalSkills === "string") { //TODO: Uncomment this after the API is updated
                        const formattedToolsTechnicalSkills:{label:string,value:string}[] = resumeObj?.toolsTechnicalSkills?.split(/[,;]+/)?.map((sk:string) => ({label:sk?.trim(),value:sk?.trim()}));
                        formattedResumeObject = {...formattedResumeObject, toolsTechnicalSkills: formattedToolsTechnicalSkills}
                    }
                    if(resumeObj?.nonTechnicalSkills && typeof resumeObj.nonTechnicalSkills === "string") {
                        const formattedNonTechnicalSkills:{label:string,value:string}[] = resumeObj?.nonTechnicalSkills?.split(/[,;]+/)?.map((sk:string) => ({label:sk?.trim(),value:sk?.trim()}));
                        formattedResumeObject = {...formattedResumeObject, nonTechnicalSkills: formattedNonTechnicalSkills}
                    }
                     
                    dispatch(setExtractedData(formattedResumeObject));
                    const addedResume = await fetch('https://testapi.tekno.ai/api/add-resume', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + getToken(),
                        },
                        body: JSON.stringify({
                            ...extractedData,
                            tailoredResumeObject: {
                                "resumeObjectWithChanges": "",
                                "matchingScore": 0,
                                "keywordsToAdd": [],
                                "missingKeywords": [],
                                "designation": "",
                                "companyName": ""
                            }
                        }),
                    });
                    const addedResumeResponse = addedResume.json();
                    console.log('Resume added successfully', addedResumeResponse);
                    navigate('/process-resume/personal-details');
                } else {
                    console.error('Upload failed');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const triggerFileInputClick = () => {
        fileInputRef.current?.click();
    };

    const userOptions: userOption[] = [
        {
            title:"Create New Resume",
            subTitle:"Create and customize your unique version yourself!",
            Icon:<CreateResumeIcon/>,
            svgClass:"svg-fill-secondary-hover-primary cursor-pointer",
            link:"/process-resume/personal-details",
        },
        {
            title:"Upload Resume",
            subTitle:"Quickly create your resume with an easy upload!",
            Icon:<UploadResumeIcon/>,
            svgClass:"svg-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: triggerFileInputClick
        },
        {
            title:"One Page Resume",
            subTitle:"Simplify your multi-page resume into a single page!",
            Icon:<OnePageResumeIcon/>,
            svgClass:"svg-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: () => {
                setResumeActionType('single-page-conversion');
                triggerFileInputClick();
            }
        },
    ];

    return (
        isLoading ?
        <Loading lottieContent={UploadingResume} title={"Uploading your Resume..."} subTitle="We’re processing your file. This may take a few moments."/> :
        <Box sx={{ display: "flex" }}>
        <Box className="side-drawer-container">
        <SideDrawer />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", width: "100%" }}>
                <Box className="user-landing-page-container" sx={{ m: 2 }}>
                    <Typography variant="h6" component="div" sx={{ display: "flex", alignItems: "center", fontWeight: "bold", mb: 2, fontSize: "24px" }}>
                        Welcome, Scott! <HandWaveIcon style={{ marginLeft: "5px" }}/>
                    </Typography>
                    <Grid container direction="column" spacing={4}/*  sx={{ my: 4 }} */>
                        <Grid item>
                            <Alert
                                severity="info"
                                icon={<Illustration_1 />}
                                sx={{
                                    borderRadius: '12px',
                                    '& .MuiAlert-icon': {
                                        padding: 0,
                                    },
                                    '& .MuiAlert-message': {
                                        fontSize: "18px",
                                        fontWeight: 'bold',
                                        color: '#162664',
                                    },
                                }}
                            >
                                Build a perfect resume today!
                            </Alert>
                        </Grid>
                        <Grid item className="top-heading-container">
                            <Box sx={{mb: 4, textAlign: "center"}}>
                                <Typography variant="h4" sx={{fontWeight: "bold"}} className="heading">Let’s get Started !</Typography>
                                <Typography variant="h6" color="#5A697E" className="sub-heding">We can begin in a few ways: pick the one that works best for you.</Typography>
                            </Box>
                        </Grid>
                        <Grid item className="landing-page-content">
                            <Grid container columnGap={5} className="card-container" justifyContent="center">
                            {userOptions.map((option:userOption) => (
                                <Grid
                                    item
                                    onClick={() => option.onClick ? option.onClick() : navigate(`${option.link}`)}
                                    sx={{ maxWidth: "350px !important" }}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                >
                                    <Card
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            borderRadius: "12px",
                                            border: "2px solid #E2E8F0",
                                            boxShadow: "none",
                                            transition: "border-color 0.3s, background-color 0.3s",
                                            cursor: "pointer",
                                            "&:hover": {
                                                border: "2px solid #5594FF",
                                                background: "linear-gradient(180deg, #E1EDFF 0%, #FFF 70%)"
                                            },
                                        }}
                                    >
                                    <CardMedia>
                                    <Box
                                    pt={4}
                                    className="flex-row-center"
                                    >
                                    {option.Icon}
                                    </Box>
                                    </CardMedia>
                                    <CardContent
                                        sx={{
                                            display:"flex",
                                            flexDirection:"column",
                                            justifyContent:"center",
                                            alignItems:"center"
                                        }}
                                    >
                                        <Typography variant="h6" component="div" sx={{fontWeight: "bold"}}>
                                        {option.title}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" sx={{ fontSize: "1.05rem", textAlign: "center" }}>
                                        {option.subTitle}
                                        </Typography>
                                    </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                            </Grid>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileSelect}
                            />
                        </Grid>
                    </Grid>
                    <HomeScreenTabs />
                </Box>
            </Box>
            <AtsResumeCheckerPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
            />
        </Box>
    )
}

export default UserLandingPage;