import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import SideDrawer from '../ProcessResume/SideDrawer';

interface LayoutProps {
  title?: React.ReactNode | string;
  sx?: SxProps<Theme>; // Optional sx prop for custom styles
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, sx, title }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box className="side-drawer-container">
        <SideDrawer />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {title && (
          <Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: "bold", m: 2, fontSize: "24px" }}>
              {title}
            </Typography>
          </Box>
        )}
        <Box sx={{ ...sx }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;