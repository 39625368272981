import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
import {ReactComponent as ProfilePictureIcon} from "../../assets/SVG/default_profile.svg";
import {ReactComponent as UploadPictureIcon} from "../../assets/SVG/upload_profile.svg";
import { Dispatch, SetStateAction, useRef } from "react";
import { GrFormEdit } from "react-icons/gr";


interface TProfileContainerProps {
    setShowEditProfilePicModal:Dispatch<SetStateAction<boolean>>
    imageStyle?:{width:string,height:string}
}

const ProfilePictureContainer:React.FC<TProfileContainerProps> = ({setShowEditProfilePicModal,imageStyle={width:"150px",height:"150px"}}) => {
    const resumeDetails = useSelector((state:any) => state.extractedData);
    const uploadPhotoRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const profileChangeHandler = () => {
        uploadPhotoRef?.current?.click();
      }

    const handleProfilePhotoUpload = (e:any):void => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.result) {
              dispatch(setExtractedData({ ...resumeDetails, profile_Picture: reader.result.toString() }));
            } else {
              console.error('Failed to read file');
            }
          };
          reader.readAsDataURL(file);
        }
        }

   return (
    <div className={"profile-picture-container"}>
    <div className={"profile-picture"}>
  {resumeDetails?.profile_Picture ? <img src={resumeDetails?.profile_Picture} alt="Uploaded"  style={imageStyle} 
     /> //className={cn({"default-profile":profilePicture === "default_profile.svg"})}
    : <ProfilePictureIcon style={{width:"3rem",height:"3rem"}}/>}
    <input
          type="file"
          id="upload-photo"
          accept="image/*"
          onClick={(e) => {
            const event = e.target as HTMLInputElement;
            event.value = "";
          }}
          onChange={handleProfilePhotoUpload}
          ref={uploadPhotoRef}
          hidden
        />
    </div>

    {resumeDetails.profile_Picture && <div className='edit-overlay'  
    onClick={()=> setShowEditProfilePicModal((prev) => !prev)}>
        <GrFormEdit color="#c1c1c1" size={"1rem"}/>
      </div>}

  </div>
   )
}
export default ProfilePictureContainer;