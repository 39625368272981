import React, { useState } from 'react';
import { IoLocationOutline } from "react-icons/io5";
import { LuPhone } from "react-icons/lu";
import { MdOutlineMail } from "react-icons/md";
import { getValidKeyValuePairs, isValueExist } from '../components/common/CommonFunctions';
import ListTypeSkills from './sections/ListTypesSkills';
import ListTypeDetails from './sections/ListTypeDetails';
import { useSelector } from 'react-redux';
import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import EditProfilePictureModal from '../components/CommonComponents/EditProfilePictureModal';
import ProfilePictureContainer from '../components/common/ProfilePictureContainer';
import SocialLinks from '../components/common/SocialLinks';


const Template9:React.FC = React.forwardRef((props:any,ref:any) => {
  const resumeDetails = useSelector((state:any) => state.extractedData);
  const tempColor = useSelector((state:any) => state.color);
  const [showEditProfilePicModal,setShowEditProfilePicModal] = useState<boolean>(false);
  const socialLinks = getValidKeyValuePairs(resumeDetails?.socialLinks);

    const setEditModalClose = () => {
      setShowEditProfilePicModal(false)
    };

   return (
    <>
    <div className={`template9-container pick-${tempColor}`} ref={ref}>

      <Box className="header">
        <Box className="header-left-section">
        <Typography component="h4" variant="h4">{resumeDetails?.name?.firstName}<br/>{resumeDetails?.name?.lastName}</Typography>
        {isValueExist(resumeDetails?.designation) && <Typography component="h5" >{resumeDetails?.designation}</Typography>}
        <Box className={"contact-info"}>
      {resumeDetails?.email && <Typography variant="body1"><span><MdOutlineMail/></span> {resumeDetails?.email}</Typography>}
        {resumeDetails?.phone &&  
        <Typography variant="body1"><LuPhone/> {resumeDetails?.phone?.join(", ")}</Typography>}
        {resumeDetails?.["location"] && 
          <Typography variant="body1"><IoLocationOutline/> {resumeDetails?.["location"]}</Typography>}  
        </Box>
        {socialLinks?.[0] && <SocialLinks links={socialLinks} iconColor={"#fff"} iconSize={"1rem"} />}  
        </Box>
        <Box className="header-right-section">
        {isValueExist(resumeDetails?.["careerObjective"]) &&  <Box id="career_objective" className={"career-objective-section"}>
        <Typography className="section-heading">Career Objective: </Typography>
            <Box>
              {resumeDetails["careerObjective"]}
            </Box>
          </Box>}
        </Box>
      </Box>

      <Box className="main-content">
            <Box className="content-left">
            <ProfilePictureContainer setShowEditProfilePicModal={setShowEditProfilePicModal} imageStyle={{width:"200px",height:"200px"}}/>

            <Box className="education-section">
            <Box className={"heading-container"}><Typography className="section-heading">Education:</Typography><div className={"horizontal-line"}><div className={"line"}></div></div></Box> 
            <List className={"education-container"}>
            {resumeDetails?.['educationalDetails'] && resumeDetails['educationalDetails'].map((education: any, index: number) => (
                <ListItem className="education" key={index}>
                <Typography variant='body1' className="degree">{education.degree} in {education.specialization}</Typography>
                <Typography variant='body1'>{education["universityCollege"]}</Typography>
                <Typography variant='body1'>GPA {education?.gpa}</Typography>
                <Typography variant='body1'>{education?.["startDate"]} - {education?.["endDate"]}</Typography>
                </ListItem>
            ))}
            </List>
            </Box>

            </Box>

            <Divider orientation='vertical' color="#fff" flexItem/>

            <Box className="content-right">
            <div className={"skills-section"}>
         <div className={"heading-container"}><Typography className="section-heading">Skills</Typography><div className={"horizontal-line"}><div className={"line"}></div></div></div>  
          {typeof resumeDetails?.["toolsTechnicalSkills"] === "string" ? <ListTypeSkills skillStyle={"skill"} skills={resumeDetails["toolsTechnicalSkills"]?.split(",")}/> :<ListTypeSkills skillStyle={"skill"} skills={resumeDetails["toolsTechnicalSkills"]}/>}
            </div>

        <div className={"experience-section"}>
        <div className={"heading-container"}><Typography className="section-heading">Experience</Typography><div className={"horizontal-line"}><div className={"line"}></div></div></div> 
          <ul className={"experience-container"}>
          {resumeDetails?.['workExperiences'] && resumeDetails?.['workExperiences'].map((experience: any, index: number) => (
            <li className={"experience"} key={index}>
              <Typography className='section-sub-heading'>{experience.role} at {experience.company}</Typography>
              <Typography className='section-duration'>{experience?.["startDate"]} - {experience?.["endDate"]}</Typography>
              
                {isValueExist(resumeDetails?.['workExperiences']?.[index]?.["keyResponsibilities"]) && 
                (typeof experience["keyResponsibilities"] === "string" 
                ? <ListTypeDetails details={experience["keyResponsibilities"]?.split(",")}/>
                 : <ListTypeDetails details={experience["keyResponsibilities"]} />)}
               
                    {isValueExist(resumeDetails?.['Work Experiences']?.[index]?.["Project Details"]) &&
                     (typeof experience["projectDetails"] === "string"
                      ? <ListTypeDetails details={experience["PprojectDetails"]?.split(",")}/> 
                      : <ListTypeDetails details={experience["projectDetails"]} />)}
                  
            </li>
          ))}
          </ul>
        </div>

            </Box>
      </Box>

      
    </div>
     {showEditProfilePicModal && <EditProfilePictureModal base64ImageUrl={resumeDetails?.profile_Picture} onClose={setEditModalClose}/>}
    </>
    
   )
});

Template9.displayName = "Template9";

export default Template9;
