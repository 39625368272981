import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ReactComponent as CardIcon}  from "../../assets/SVG/cardIcon.svg"
import { IconButton } from '@mui/material';
import { cleanSummaryHTMLstring } from '../../components/common/CommonFunctions';

interface CardDetails {
    details : {
    heading:string,
    subHeading?:string,
    startDate?:string,
    endDate?:string,
    city?:string,
    aboutText?:string,
    company?:string,
    index?:number,
    },
    handleEditClick?:(index?:number) => void;
    handleDeleteClick?:(index?:number) => void;
}

const EducationCard:React.FC<CardDetails> = ({details, handleEditClick, handleDeleteClick}) => {
  return (
    <Card className="education-card-container" sx={{ width: "100%",boxShadow:"none",border:"1px solid #e2e8f0",borderRadius:"8px" }}>
      <CardContent className='card-content'         
      sx={{
          '&:last-child': {
            paddingBottom: 0, // Remove padding from last child
          },
        }}
>       
        <Box className="top-section" sx={details?.aboutText ? {borderBottom:"1px dashed #e2e8f0",marginBottom:"0.5rem"} : {}}>
          <Box className="top-section-left">
              <CardIcon/>
          </Box>
          <Box className="top-section-right">
            <Box className="upper">
            <Typography variant="h5" component="div" className="heading">
            {details?.heading}{details?.company && ` at ${details?.company}`}
          </Typography>
          <Typography className="edit-button" onClick={()=>handleEditClick && handleEditClick(details?.index ?? 1)}>Edit</Typography>
            </Box>
            <Box className="lower">
            {(details?.startDate  || details?.endDate) ? <Typography variant="h6" className="sub-heading" gutterBottom>
            {details?.startDate} to {details?.endDate}
            </Typography> : <Typography variant="h6" className="sub-heading">{""}</Typography>}
            <Typography className="delete-button"  onClick={()=>handleDeleteClick && handleDeleteClick(details?.index ?? 1)}>
              Delete
            </Typography>
            </Box>

          </Box>
        </Box>

        {details?.aboutText && <Box className="middle-section">
            <Box className="about-section">
                <Typography variant="body1" component="div" className="about-text ui-text-3-color" dangerouslySetInnerHTML={{ __html: cleanSummaryHTMLstring(details?.aboutText) }}>
                </Typography>
            </Box>
        </Box>}

      </CardContent>
      {/* <CardActions className="bottom-section">
        <Box className="action-container">
            <Box className="edit-button" onClick={handleEditClick}><EditOutlinedIcon className="edit-icon"/></Box>
            <Box className="delete-button" onClick={handleDeleteClick}><DeleteOutlineOutlinedIcon className="delete-icon"/></Box>
        </Box>
      </CardActions> */}
    </Card>
  );
}

export default EducationCard;
