import './App.css';
import "./styles/style.scss";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import UserLandingPage from './UserLandingPage';
import ProcessResumeFeature from './ProcessResume/ProcessResumeFeature';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TemplatesList from './components/templates/TemplatesList';
import { Login, Register } from './components/auth';
import { Provider, useSelector } from 'react-redux';
import { createStore } from 'redux';
import reducer from './redux/reducer';
import LandingPage from './LandingPage/LandingPage';
import CreateAccount from './components/Accounts/CreateAccount';
import EditAccount from './components/Accounts/EditAccount';
import ForgotPassword from './components/Accounts/ForgotPassword';
import ATSCheckResult from './components/ATSChecker/ATSCheckResult';
import { ILoggedinUserData } from './components/utils';
import { getToken } from './components/auth/storeToken';
import CssBaseline from '@mui/material/CssBaseline';
import { Padding } from '@mui/icons-material';
import SavedResumes from './components/Accounts/SavedResume';
import { RefProvider } from './templates/TemplateSectionsRef';
import 'react-quill/dist/quill.snow.css'; // Snow theme (you can use "bubble" as well)
import JobBoard from './components/JobBoard/JobBoard';
import UserDashboard from './components/UserDashboard/UserDashboard';
import ATSChecker from './components/ATSChecker/ATSChecker';

const theme = createTheme({
  palette: {
    text: {
      secondary: "#828ba2"
    }
  },
  typography: {
    fontFamily: [
      'TT Commons',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      fontSize: '1rem',

     "@media (max-height:910px)":{
      fontSize: '0.9375rem',
     }
    },
  
  },
  components: {
    // MuiAlert: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiAlert-standardInfo': {
    //         background: "linear-gradient(to left, #EAF0FF, #FFF3F9)" // Custom background color for info alerts
    //       },
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // below padding eefects the padding of all text fields
          padding:"0.6rem",
          borderRadius: '10px',
          backgroundColor: "#fff",
          '& fieldset': {
            borderRadius: '10px',
            border: '1px solid lightgray',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #5594ff', // your custom focus color
          },
        },
        input: {
          padding: '0rem',
          '&::placeholder': {
            color: '#cbcbcb', // Replace with your desired placeholder color
            opacity: 1,   // Ensures the placeholder is fully visible
          },
        },
        inputMultiline: {
          padding: '0rem', // Reduced padding for multiline text fields
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          padding: '0px', // This is the crucial part to ensure no padding
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          backgroundColor: "#fff",
          color: "#344054",
          padding: "0rem",
          '& fieldset': {
            borderColor: 'lightgrey',
            borderRadius: '10px',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0.1rem 0.5rem',
          borderRadius: '10px',
        },
        input: {
          padding: '0px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#667085', // Change label color
          fontSize: '14px', // Customize font size
          fontWeight:500,
          '&.Mui-focused': {
            color: 'blue', // Change color when focused
          },
          marginBottom:"0.25rem",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '4px 18px', 
          textTransform: 'none',
          fontSize:"1rem", 
          boxShadow: 'none',
        },
        contained: {
          backgroundColor: '#5598ff',
          color: '#fff',
          '&:hover': {
             backgroundColor: '#5598ff',
          },
        },
        outlined: {
          background:"#fff",
          color:"#1e293b",
          border:`1px solid #e2e8f0` ,
          borderRadius: "7px",
          '&:hover': {
            background:"#fff",
            border:"1px solid #e2e8f0",
            boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
          },
        },
        text: {
          color: '#1976d2', 
        },
      },
    },
  },
});

export const quillModules = {
  toolbar: [
    [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
    ['bold', 'italic', 'underline'], // Text formatting
    ['clean'] // Clean formatting
  ],
};



function AuthRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const accessToken = useSelector((state:any) => state.accessToken);
  const extractedData = useSelector((state:any) => state.extractedData);
  const loggedInUserData: ILoggedinUserData = useSelector((state: any) => state.loggedInUserData);

  const isAuthenticated = accessToken || (loggedInUserData && Object.keys(loggedInUserData).length > 0) || getToken();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  return children;
}

function RedirectToLoginOrDashboard() {
  const accessToken = useSelector((state:any) => state.accessToken);
  const loggedInUserData: ILoggedinUserData = useSelector((state: any) => state.loggedInUserData);
  const isAuthenticated = accessToken || (loggedInUserData && Object.keys(loggedInUserData).length > 0) || getToken();

  return isAuthenticated ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />;
}


function App() {

const store = createStore(reducer);

  return (
    <Provider store={store}>
      <RefProvider>
        <CssBaseline/>
        <ThemeProvider theme={theme}>
          <main>
            <div className='app-page-container'>
              <Router>
              <Routes>
                <Route path="/" element={<RedirectToLoginOrDashboard />} />
                <Route path="landing-page" element={<LandingPage />} />
                <Route path="/dashboard" element={<AuthRoute><UserDashboard /></AuthRoute>} />
                <Route path="login" element={<Login />} />
                <Route path="create-account" element={<CreateAccount />} />
                <Route path="register" element={<Register />} />
                <Route path="edit-account" element={<EditAccount />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="/my-resumes" element={<AuthRoute><UserLandingPage /></AuthRoute>} />
                <Route path="select-template" element={<AuthRoute><TemplatesList/></AuthRoute>}/>
                <Route path="ats-check-result" element={<AuthRoute><ATSCheckResult/></AuthRoute>}/>
                <Route path="job-board" element={<AuthRoute><JobBoard/></AuthRoute>}/>
                <Route path="process-resume/*" element={<AuthRoute><ProcessResumeFeature/></AuthRoute>}/>
                <Route path="ats-checker/*" element={<AuthRoute><ATSChecker/></AuthRoute>}/>
              </Routes>
              </Router>
            </div>
          </main>
        </ThemeProvider>
      </RefProvider>
    </Provider>
  );
}

export default App;