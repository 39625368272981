import { Box, IconButton, TextField, Typography } from "@mui/material";
import ResumeScoreProgressBar from "./ResumeScoreProgressBar";
import { useRef, useState } from "react";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import {ReactComponent as PersonalDetailEditIcon } from "../assets/SVG/personal-edit-icon.svg";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { getUsernameFromToken } from "../services/getUserFromToken";
import PersonalDetails from './EditForms/PersonalDetails';

const NameScoreWrapper = () => {
    const token = localStorage.getItem("authToken");
    
    const username = token ? getUsernameFromToken(token) : null;  
    const [name,setName] = useState<string>(username ?? "Scott");
    const [edit,setEdit] = useState<boolean>(false);
    const initialName = useRef<string>(name);

    const handleCloseEditor = () => {
        setName(initialName.current);
        setEdit(false);
    }

    const handleEditDone = () => {
        initialName.current = name;
        setEdit(false);
    }

  return (
    <Box className="name-score-wrapper-container">
        <Box className="name-container">
            {!edit ? <Box className="name-wrapper">
             <Typography sx={{fontSize:"20px", fontWeight:"600",
                "@media(min-height:1070px)":{
                    fontSize:"22px",
                }
             }}>{name}</Typography>
             <Box sx={{display:"flex",justifyContent:"center",cursor:"pointer"}}>       
             <PersonalDetailEditIcon onClick={()=>setEdit(true)}/>
             </Box>
            </Box> 
            :<Box className="edit-wrapper">
                <TextField onChange={(e)=> setName(e.target.value)} value={name} size="small" />
                <CloseIcon sx={{color:"#EF4444",cursor:"pointer"}} onClick={handleCloseEditor}/>
                <IconButton disabled={!name} sx={{
                    "&:hover":{
                    background:"none",
                },
                cursor: !name ? 'not-allowed' : 'pointer',
                }}><DoneIcon sx={{color:name ? "#19A44C" : "lightgray"}} onClick={handleEditDone} /></IconButton>
            </Box>}
        </Box>
        <Box className="progress-container">
            <ResumeScoreProgressBar/>
        </Box>
    </Box>
  )
}
export default NameScoreWrapper;