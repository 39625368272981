import Layout from "../Layout";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Illustration_4 } from "../Icons";
import { Close, Search } from "@mui/icons-material";
import { useState } from "react";
import { AuthInputFieldStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import ResultsList from "./ResultsList";
import ProcessingPopup from "./ProcessingPopup";
import { extractFaceFromPDF, IResume, WorkExperienceTypes } from "../utils";
import * as ATSCheckScreen from "./../../assets/lottie/ATSCheckScreen.json";
import Loading from "../Loading/Loading";

const ATSChecker = () => {

  const [searchQuery, setSearchQuery] = useState('');
  const [popupDetails, setPopupDetails] = useState({
    open: false,
    title: "",
    subTitle: "",
    progress: 0,
    page: 0,
    resumeObject: {} as IResume
  })

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleFileSelect = async (files: any) => {
      setPopupDetails((prevState) => ({
        ...prevState,
        open: true,
        title: "Uploading Resume...",
        subTitle: "Please wait, it might take few mins to upload.",
        progress: 20,
        page: 1,
      }));
      if (files?.length) {
          const file = files[0];
          const formData = new FormData();
          formData.append('file', file);
          console.log(process.env.REACT_APP_API_URL,"from API")

          try {
              const response = await fetch(`https://testapi.tekno.ai/api/extract-text`, {
                  method: 'POST',
                  body: formData,
              });
              if (response.ok) {
                  const {textContent} = await response.json();
                  const requestData = { content: textContent } as any;
                  const endpointURL = `${process.env.REACT_APP_API_URL}/generate-content`;
                  const modelResponse = await fetch(endpointURL, {
                      method: 'POST',
                      body: JSON.stringify(requestData),
                      headers: {
                          'Content-Type': 'application/json',
                      },
                  });
                  const resumeObj = await modelResponse.json();

                  /**
                   * Converting pdf to image
                  */

                  const reader:any = new FileReader();
                  reader.onloadend = () => {
                      const base64string = reader.result.split(',')[1];
                      const extractedFace = extractFaceFromPDF(base64string);
                      if (extractedFace) {
                          resumeObj.profile_Picture = extractedFace;
                      }
                  };
                  reader.readAsDataURL(file);
                  console.log("resumeObj", resumeObj);
                  // const imgDataUrl = convertToImage(file);

                  //formatting resume object for projects section
                  let formattedResumeObject = resumeObj;
                  if(resumeObj?.projects?.[0]) {
                      const formattedProjects = resumeObj?.projects?.map((proj:any)=>({...proj,organization:proj?.title,courseDescription:proj?.description}));
                      formattedResumeObject = {...formattedResumeObject, projects: formattedProjects}
                  }
                  if(resumeObj?.workExperiences?.[0]) {
                      const formattedExperience:WorkExperienceTypes = resumeObj?.workExperiences?.map((exp:WorkExperienceTypes) => ({...exp,jobType:exp?.jobType ?? "fullTime",location:exp?.location ?? ""}));
                      formattedResumeObject = {...formattedResumeObject, workExperiences: formattedExperience}
                  }
                  if(resumeObj?.toolsTechnicalSkills && typeof resumeObj.toolsTechnicalSkills === "string") {
                      const formattedToolsTechnicalSkills:{label:string,value:string}[] = resumeObj?.toolsTechnicalSkills?.split(/[,;]+/)?.map((sk:string) => ({label:sk?.trim(),value:sk?.trim()}));
                      formattedResumeObject = {...formattedResumeObject, toolsTechnicalSkills: formattedToolsTechnicalSkills}
                  }
                  if(resumeObj?.nonTechnicalSkills && typeof resumeObj.nonTechnicalSkills === "string") {
                      const formattedNonTechnicalSkills:{label:string,value:string}[] = resumeObj?.nonTechnicalSkills?.split(/[,;]+/)?.map((sk:string) => ({label:sk?.trim(),value:sk?.trim()}));
                      formattedResumeObject = {...formattedResumeObject, nonTechnicalSkills: formattedNonTechnicalSkills}
                  }
                  
                  setPopupDetails((prevState) => ({
                    ...prevState,
                    open: true,
                    title: "Resume Uploaded Successfully",
                    subTitle: "In the next step, you can enter the job details to tailor this resume.",
                    progress: 100,
                    page: 1,
                    resumeObject: formattedResumeObject
                  }));
                  // navigate('/process-resume/personal-details');
              } else {
                  console.error('Upload failed');
              }
          } catch (error) {
              console.error('Error uploading file:', error);
          } finally {
              // setOpenPopup(false);
          }
      }
  };

  return (
    <Layout sx={{ m: 2 }} title="Welcome, Scott!">
      <Grid container direction="column" spacing={4}/*  sx={{ my: 4 }} */>
        <Grid item>
            <Alert
                severity="info"
                icon={<Illustration_4 />}
                sx={{
                    borderRadius: '12px',
                    '& .MuiAlert-icon': {
                        padding: 0,
                    },
                    '& .MuiAlert-message': {
                        fontSize: "14px",
                        fontWeight: 'bold',
                        color: '#162664',
                    },
                }}
            >
                Build a ATS Friendly resume today - All the major employers use ATS scores today to auto filter resumes with relevance.
            </Alert>
        </Grid>
        <Grid item className="top-heading-container">
          <Box sx={{mb: 4, textAlign: "center"}}>
              <Typography variant="h4" sx={{fontWeight: "bold"}} className="heading">Pick a Resume to verify ATS Score (%)</Typography>
              <Typography variant="h6" color="#5A697E" className="sub-heding">We can begin in a few ways: pick the one that works best for you.</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontSize:"1.3rem", fontWeight:"600" }}>Select Resume</Typography>
            <TextField
              name="search"
              required
              fullWidth
              id="search"
              sx={{
                ...AuthInputFieldStyle,
                maxWidth: {
                  xs: '50%', // 100% width on extra-small screens
                  sm: '50%', // 100% width on small screens
                  md: '30%',
                },
                mr: 2
              }}
              placeholder="Search documents"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          </Grid>
      </Grid>
      <ResultsList onFileUpload={handleFileSelect}/>
      <ProcessingPopup
        open={popupDetails.open}
        onClose={() => setPopupDetails((prevState) => ({ ...prevState, open: false }))}
        popupDetails={popupDetails}
        setPopupDetails={setPopupDetails}
      />
      {
        popupDetails.page === 3 && popupDetails.progress < 100 &&
        <Loading
          title={"Analyzing Your Resume for ATS Score"}
          subTitle={"Ensuring your resume is optimized and ready for ATS."}
          lottieContent={ATSCheckScreen}
        />
      }
    </Layout>
  );
};

export default ATSChecker;