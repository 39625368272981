import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Box } from '@mui/material';
import { extractFaceFromPDF } from '../utils';
import { setExtractedData } from '../../redux/actions';
import { useDispatch } from 'react-redux';

interface AtsResumeCheckerPopupProps {
    open: boolean;
    onClose: () => void;
}

const AtsResumeCheckerPopup: React.FC<AtsResumeCheckerPopupProps> = ({ open, onClose }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [jobDescription, setJobDescription] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        if (selectedFile && jobDescription) {
          setLoading(true);
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('jobDescription', jobDescription);
          try {
            // Step 1: Extract text
            const extractTextResponse = await fetch(`https://testapi.tekno.ai/api/extract-text`, {
              method: 'POST',
              body: formData,
            });
    
            if (!extractTextResponse.ok) {
              throw new Error('Extract text failed');
            }
    
            const { textContent } = await extractTextResponse.json();
    
            // Step 2: Generate content
            const requestData = { content: textContent };
            const endpointURL = `${process.env.REACT_APP_API_URL}/generate-content`;
            const generateContentResponse = await fetch(endpointURL, {
              method: 'POST',
              body: JSON.stringify(requestData),
              headers: {
                'Content-Type': 'application/json',
              },
            });
    
            if (!generateContentResponse.ok) {
              throw new Error('Generate content failed');
            }
    
            const resumeObj = await generateContentResponse.json();
    
            // Step 3: Convert PDF to image and update resumeObj
            const reader:any = new FileReader();
            reader.onloadend = async () => {
              const base64string = reader.result.split(',')[1];
              const extractedFace = await extractFaceFromPDF(base64string);
              if (extractedFace) {
                resumeObj.profile_Picture = extractedFace;
              }
    
              // Step 4: Send resumeObj to ATS checker
              const atsCheckerFormData = new FormData();
              atsCheckerFormData.append('file', selectedFile);
              atsCheckerFormData.append('jobDescription', jobDescription);
              atsCheckerFormData.append('resumeObject', JSON.stringify(resumeObj));
    
              const atsCheckerResponse = await fetch('https://be.tekno.ai/ats-checker', {
                method: 'POST',
                body: atsCheckerFormData,
              });
    
              if (!atsCheckerResponse.ok) {
                throw new Error('ATS checker failed');
              }
    
              const atsCheckerData = await atsCheckerResponse.json();
              console.log('ATS Check Result:', atsCheckerData);
    
              dispatch(setExtractedData(resumeObj));
              setLoading(false);
              navigate('/ats-check-result', { state: { atsCheckResult: atsCheckerData } });
              onClose();
            };
            reader.readAsDataURL(selectedFile);
          } catch (error) {
            console.error('Error:', error);
            setLoading(false);
          }
        }
      };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>ATS Resume Checker</DialogTitle>
            <DialogContent>
                <input type="file" onChange={handleFileChange} />
                <TextField
                    label="Job Description"
                    multiline
                    rows={4}
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                {loading && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AtsResumeCheckerPopup;