import React, { useEffect } from "react";
import Box  from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography  from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { useNavigate } from 'react-router-dom';
import { setSelectedTemplateId } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { TemplateCategory, Templates } from "../utils";
import { ArrowBack } from "@mui/icons-material";

const TemplatesList = () => {

    const navigate = useNavigate();
    const selectedTemplatedId = useSelector((state: any) => state.templateId);

    const [activeChips, setActiveChips] = React.useState<string[]>([TemplateCategory.All]);

    const [activeTemplate, setActiveTemplate] = React.useState<string>(selectedTemplatedId || "T1");

    const dispatch = useDispatch();

    const handleChipClick = (label: string) => {
        setActiveChips([label]);

        // prev => {
        //     if (prev.includes(label)) {
        //         return prev.filter(chip => chip !== label); // Remove chip if already selected
        //     } else {
        //         return [...prev, label]; // Add chip if not selected
        //     }
        // }
    };

    useEffect(() => {
        dispatch(setSelectedTemplateId(activeTemplate));
    }, [activeTemplate]);

    return  (
        <Container style={{ marginTop: "20px" }}>
            <Box display="flex" justifyContent="space-between">
                <Typography variant="h5" gutterBottom>
                    Select your preferred template below
                </Typography>
                 <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBack />}>Back to Edit</Button>
                </Box>
            </Box>
            <Box sx={{padding:"1rem 0"}} >
                <Stack direction="row" spacing={1} sx={{paddingBottom:"1rem"}}>
                    {Object.values(TemplateCategory).map((label) => (
                        <Chip label={label} color={activeChips.includes(label) ? "primary" : "default"} onClick={() => handleChipClick(label)} variant={activeChips.includes(label) ? 'filled' : 'outlined'} className="template-category-chip"/>
                    ))}
                </Stack>
            </Box>
            <Grid container spacing={2} sx={{height:"80vh",overflow:"auto"}}>
                {Templates.filter(template => {
                    if(activeChips?.[0] === "All") {
                        return true;
                    }
                    else {
                        return template.tag === activeChips?.[0]
                    }
   
                }).map((template, index) => ( //.filter(template => activeChips.length > 0 ? activeChips.includes(template.tag) : true)
                    <Grid item xs={4} key={template.id} > {/* Adjusted for 4x4 layout */}
                    <Box
                        onClick={() => setActiveTemplate(template.id) }
                        className={`template-preview-box ${activeTemplate === template.id ? 'active' : ''}`}
                        sx={{
                            border: 1,
                            borderRadius: 2,
                            height: "15rem",
                            borderColor: 'grey.400', // Adjust as needed
                            minHeight: "15rem",
                        }}
                    >
                        {
                            template.img && <img src={template.img} className="template-preview-img"/>
                        }
                    </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export default TemplatesList;