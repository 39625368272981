import { CourseType } from "../utils";
import { SocialType } from "./SocialLinks";

export const isValueExist = (field:string):boolean => {
    if(field && field!=="NA") {
      return true;
    }
    return false;
  }

// Function to get valid key-value pairs as an array of objects in the format { key, value }
export const getValidKeyValuePairs = (obj: Record<keyof SocialType, string | null>): { key: keyof SocialType, value: string }[] => {
  return Object.keys(obj)
    .filter(key => {
      const value = obj[key as keyof SocialType];
      return value && typeof value === 'string' && value !== 'NA'; // Only keep valid string values
    })
    ?.map(key => ({ key:key as keyof SocialType, value: obj[key as keyof SocialType] || "" })); // Return each valid key-value as an object
};

//Common utils to return error for link input
export const isValidUrl = (addLink:boolean,value:string) => {
  if(!addLink) return true;
  if(addLink){
    if(!value) return false;
    const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/
    return urlRegex.test(value);
  };

}

export const getCustomDataObject = (customSectionObject:{[key:string]:{label:string,value:CourseType[]}}) => {
  if(!customSectionObject) {
    return [];
  }
  const keys = Object.keys(customSectionObject);
  const customSectionArray = keys.map(key => ({label:customSectionObject[key]?.label,value:customSectionObject[key]?.value}));
  return customSectionArray;
}

export const cleanSummaryHTMLstring = (summaryString:string):string =>  {
  if(!summaryString) return "";
  return summaryString.replace(/(<ul>\s*(<li>\s*<br>\s*<\/li>\s*)+<\/ul>)|(<p>\s*<br>\s*<\/p>)/g, '');
}

export const seprateExtractedBulletPointsStringToArray = (inputString:string) => {
  const ouputString =  inputString
  ?.split('●')
  ?.map(point => point.trim())
  ?.filter(point => point.length > 0)
  ?.map(point => `<ul><li>${point}</li></ul>`)?.join("");
  return ouputString;
}

export const regexTotestAddKeyWord = (str:string) => {
  const regex = /^Add\s/g;
 return regex.test(str);
}