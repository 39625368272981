import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { EducationTypes, IResume, isValueExist, WorkExperienceTypes } from '../components/utils';

Font.register({
  family: 'Aptos',
  src: 'http://localhost:3000/Aptos.ttf',
});

Font.register({
  family: 'Aptos Bold',
  src: 'http://localhost:3000/Aptos-Bold.ttf',
});


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',   
 // Set the page to horizontal layout
    padding: 20,
  },
  leftColumn: {
    flex: 1, // Occupy half of the page width
    marginRight: 20, // Add a margin between columns
  },
  rightColumn: {
    flex: 0.5,
    padding: 20,
    backgroundColor: 'rgb(246, 247, 248)', // Set the background color
  },
  name: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'rgb(78, 96, 113)'
  },
  jobTitle: {
    fontSize: 14,
    color: 'rgb(89, 89, 89)'
  },
  mt20: {
    marginTop: 20,
  },
  mb0: {
    marginBottom: 0,
  },
  mb10: {
    marginBottom: 10,
  },
  sectionHeader: {
    fontSize: 16,
    color: 'rgb(78, 96, 113)',
    fontFamily: "Aptos Bold",
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionSubHeader: {
    fontSize: 16,
  },
  sectionContent: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  skills: {
    fontSize: 12,
    marginBottom: 10,
  },
  languages: {
    fontSize: 12,
    marginBottom: 10,
  },
  contact: {
    fontSize: 12,
    marginTop: 20,
  },
  atsIssue: {
    position: "relative"
  },
  atsIssueHigh: {
    padding: 5,
    border: "1.5px solid #EF4444",
    backgroundColor: "rgba(255, 182, 182, 0.20)",
  },
  atsIssueMedium: {
    padding: 5,
    border: "1.5px solid #FF8A1C",
    backgroundColor: "rgba(255, 224, 156, 0.20)"
  },
  atsIssueLow: {
    padding: 5,
    border: "1.5px solid #E9CB33",
    backgroundColor: "rgba(233, 203, 51, 0.20)"
  },
  atsIssueFixed: {
    padding: 5,
    border: "1px solid #19A44C"
  },
  fixATSButton: {
    width: 20,
    height: 20,
    position: "absolute",
    right: -10,
    top: -10,
  },
  fixATSButtonImg: {
    width: 20,
    height: 20
  }
});

const Template = (props: any) => {

  let {extractedData, pageWidth, isATSPreview = false} : {extractedData: any, pageWidth: any, isATSPreview: boolean} = props;

  /* extractedData = {
    "name": {
        "firstName": "CHARLES",
        "lastName": "MCTURLAND"
    },
    "profilePicture": null,
    "email": "cmcturland@email.com",
    "phone": [
        "(123) 456-7890"
    ],
    "location": "New York, NY",
    "socialLinks": {
        "personal_Website": "NA",
        "linkedIn_URL": "NA",
        "github_URL": "NA",
        "twitter_X_URL": null
    },
    "careerObjective": "NA",
    "designation": "SOFTWARE ENGINEER",
    "educationalDetails": [
        {
            "universityCollege": "University of Pittsburgh",
            "degree": "B.S.",
            "location": null,
            "isEdit": null,
            "isNewEntry": null,
            "index": "2c1c5226-0d0c-48f9-b1cf-cc5ce2006745",
            "specialization": "Computer Science",
            "gpa": "NA",
            "startDate": "September 2008",
            "endDate": "April 2012"
        }
    ],
    "workExperiences": [
        {
            "startDate": "January 2015",
            "endDate": "current",
            "company": "Embark",
            "jobType": null,
            "role": "Software Engineer",
            "location": null,
            "isEdit": null,
            "isNewEntry": null,
            "index": "872cd265-8730-4356-9b3a-440fab95e190",
            "keyResponsibilities": "Worked with product managers to re-architect a multi-page web app into a single page web-app, boosting yearly revenue by $1.4M\nConstructed the logic for a streamlined ad-serving platform that scaled to our 35M users, which improved the page speed by 15% after implementation\nT ested software for bugs and operating speed, ﬁxing bugs and documenting processes to increase efﬁciency by 18%\nIterated platform for college admissions, collaborating with a group of 4 engineers to create features across the software",
            "keyResponsibilitiesArray": [
                "Worked with product managers to re-architect a multi-page web app into a single page web-app, boosting yearly revenue by $1.4M",
                "Constructed the logic for a streamlined ad-serving platform that scaled to our 35M users, which improved the page speed by 15% after implementation",
                "T ested software for bugs and operating speed, ﬁxing bugs and documenting processes to increase efﬁciency by 18%",
                "Iterated platform for college admissions, collaborating with a group of 4 engineers to create features across the software"
            ],
            "projectDetails": "NA",
            "toolsTechnologies": "NA"
        },
        {
            "startDate": "April 2012",
            "endDate": "January 2015",
            "company": "MarketSmart",
            "jobType": null,
            "role": "Software Engineer",
            "location": null,
            "isEdit": null,
            "isNewEntry": null,
            "index": "34b91e61-368e-43c3-a9ab-24061be9e5ab",
            "keyResponsibilities": "Built RESTful APIs that served data to the JavaScript front-end based on dynamically chosen user inputs that handled over 500,000 concurrent users\nBuilt internal tool using NodeJS and Pupeteer.js to automate QA and monitoring of donor-facing web app, which improved CTR by 3%\nReviewed code and conducted testing for 3 additional features on donor-facing web app that increased contributions by 12%",
            "keyResponsibilitiesArray": [
                "Built RESTful APIs that served data to the JavaScript front-end based on dynamically chosen user inputs that handled over 500,000 concurrent users",
                "Built internal tool using NodeJS and Pupeteer.js to automate QA and monitoring of donor-facing web app, which improved CTR by 3%",
                "Reviewed code and conducted testing for 3 additional features on donor-facing web app that increased contributions by 12%"
            ],
            "projectDetails": "NA",
            "toolsTechnologies": "NA"
        },
        {
            "startDate": "April 2011",
            "endDate": "March 2012",
            "company": "Marketing Science Company",
            "jobType": null,
            "role": "Software Engineer Intern",
            "location": null,
            "isEdit": null,
            "isNewEntry": null,
            "index": "21bea90f-aa2b-41d8-a695-b6e1fba432b4",
            "keyResponsibilities": "Partnered with a developer to implement RESTful APIs in Django, enabling analytics team to increase reporting speed by 24%\nUsing Selenium I built out a unit testing infrastructure for a client application that reduced the number of bugs reported by the client by 11% month over month",
            "keyResponsibilitiesArray": [
                "Partnered with a developer to implement RESTful APIs in Django, enabling analytics team to increase reporting speed by 24%",
                "Using Selenium I built out a unit testing infrastructure for a client application that reduced the number of bugs reported by the client by 11% month over month"
            ],
            "projectDetails": "NA",
            "toolsTechnologies": "NA"
        }
    ],
    "projects": [
        {
            "title": "Poker Simulation",
            "description": "Built a full-stack web app to allow users to simulate and visualize outcomes of poker hands against opponents of different play styles using open source cards.js on the front-end\nUtilized sci-kit learn in Python to simulate possible outcomes under different scenarios that the user chose",
            "toolsTechnologies": "NA"
        }
    ],
    "courses": null,
    "certifications": "NA",
    "toolsTechnicalSkills": "Python (Django)\nJavascript (NodeJS ReactJS, jQuery)\nSQL (MySQL, PostgreSQL, NoSQL)\nHTML5/CSS\nAWS\nUnix, Git",
    "toolsTechnicalSkillsModified": [
        {
            "label": "Python (Django)",
            "value": "Python (Django)"
        },
        {
            "label": "Javascript (NodeJS ReactJS, jQuery)",
            "value": "Javascript (NodeJS ReactJS, jQuery)"
        },
        {
            "label": "SQL (MySQL, PostgreSQL, NoSQL)",
            "value": "SQL (MySQL, PostgreSQL, NoSQL)"
        },
        {
            "label": "HTML5/CSS",
            "value": "HTML5/CSS"
        },
        {
            "label": "AWS",
            "value": "AWS"
        },
        {
            "label": "Unix, Git",
            "value": "Unix, Git"
        }
    ],
    "programmingLanguages": ["Python, Javascript"],
    "interests": "NA",
    "languages": [],
    "hobbies": [],
    "tailoredResumeObject": null,
    "profile_Picture": null
} */

  return (
  <Document>
    <Page size="A4" style={{...styles.page, width: pageWidth}}>
      <View style={styles.leftColumn}>
        <Text style={styles.name}>
          {extractedData.name.firstName} <Text style={{fontWeight: "bold", fontFamily: "Aptos Bold"}}>{extractedData.name.lastName}</Text>
        </Text>
        <Text style={styles.jobTitle}>{extractedData.designation}</Text>
        <View style={{ marginTop: 20 }}>
          <Text style={styles.sectionHeader}>WORK EXPERIENCE</Text>
          {extractedData.workExperiences && extractedData.workExperiences.map((exp: WorkExperienceTypes, index: number) => (
            <View key={index} style={isATSPreview ? index === 0 ? {...styles.atsIssueHigh, marginBottom: 10} : index === 1 ? {...styles.atsIssueFixed, marginBottom: 10} : {} : {}}>
              {isATSPreview &&
                <View style={styles.fixATSButton}>
                {index === 0 && 
                  <>
                    <Image src={"http://localhost:3000/img/ats-ai-fix.png"} style={styles.fixATSButtonImg}/>
                    <View style={{...styles.fixATSButtonImg, position: 'absolute', top: 0, left: 0}}>
                      <Text style={{fontSize: "0.1px", color: "transparent"}}>ATS_ISSUE-WORK_EXPERIENCE-{exp.index}</Text>
                    </View>
                  </>
                }
                {index === 1 && <Image src={"http://localhost:3000/img/ats-fixed-icon.png"} style={styles.fixATSButtonImg}/>}
              </View>
              }
              <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{exp.company}, {exp.role}</Text>
              <Text style={styles.sectionContent}>{exp.startDate as any} - {exp.endDate as any}</Text>
              <Text style={{...styles.sectionContent, ...styles.mb0}}>{exp.keyResponsibilities}</Text>
            </View>
          ))}
        </View>

        <View style={{ marginTop: 20 }}>
          <Text style={styles.sectionHeader}>EDUCATION</Text>
          {extractedData.educationalDetails && extractedData.educationalDetails.map((edu: EducationTypes, index: number) => (
            <View key={index}>
              <Text style={{...styles.sectionHeader, ...styles.sectionSubHeader}}>{edu.universityCollege}</Text>
              <Text style={styles.sectionContent}>{edu.degree} in {edu.specialization}</Text>
              <Text style={{...styles.sectionContent, ...styles.mb0}}>{edu.startDate as any} - {edu.endDate as any}</Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.rightColumn}>
        <View>
          <Text style={styles.sectionHeader}>CONTACT</Text>
          <Text style={styles.contact}>{extractedData.phone?.join(", ")}</Text>
          <Text style={styles.contact}>{extractedData.email}</Text>
          <Text style={styles.contact}>{extractedData.location}</Text>
        </View>

        <View style={isATSPreview ? {...styles.atsIssueMedium, marginTop: 20} : {marginTop: 20} }>
          <Text style={styles.sectionHeader}>SKILLS</Text>
          {isATSPreview &&
            <View style={styles.fixATSButton}>
              <Image src={"http://localhost:3000/img/ats-ai-fix.png"} style={styles.fixATSButtonImg}/>
              <View style={{...styles.fixATSButtonImg, position: 'absolute', top: 0, left: 0}}>
                <Text style={{fontSize: "0.1px", color: "transparent"}}>ATS_ISSUE-SKILLS</Text>
              </View>
            </View>
          }
          {extractedData.toolsTechnicalSkills && extractedData.toolsTechnicalSkills.map((skill: { label: string; value: string; }, index: number) => (
            <Text key={index} style={styles.skills}>{skill.label}</Text>
          ))}
        </View>

        <View style={isATSPreview ? {...styles.atsIssueLow, marginTop: 20} : {marginTop: 20} }>
          <Text style={styles.sectionHeader}>LANGUAGES</Text>
          {isATSPreview &&
            <View style={styles.fixATSButton}>
              <Image src={"http://localhost:3000/img/ats-ai-fix.png"} style={styles.fixATSButtonImg}/>
              <View style={{...styles.fixATSButtonImg, position: 'absolute', top: 0, left: 0}}>
                <Text style={{fontSize: "0.1px", color: "transparent"}}>ATS_ISSUE-LANGUAGES</Text>
              </View>
            </View>
          }
          {extractedData.programmingLanguages && extractedData.programmingLanguages.map((language: string, index: number) => (
            <Text key={index} style={styles.languages}>• {language}</Text>
          ))}
        </View>
      </View>
    </Page>
  </Document>
  );
};

export default Template;