import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import {ReactComponent as DraftIcon} from "../assets/SVG/save-draft-icon.svg";
import {ReactComponent as CancelModalIcon} from "../assets/SVG/cancel-modal.svg";
import { summarSectionModalstyle } from "./EditForms/Summary";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setExtractedData } from "../redux/actions";
import { useSelector } from "react-redux";
import { IResume } from "../components/utils";
import { getToken } from "../components/auth/storeToken";

interface ISaveAsDraftEntryModal {
    saveAsDraftModal:boolean;
    setSaveAsDraftModal:Dispatch<SetStateAction<boolean>>;
    modalUrls:string;
}

const SaveAsDraftModal:React.FC<ISaveAsDraftEntryModal> = ({saveAsDraftModal,setSaveAsDraftModal,modalUrls}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const extractedData = useSelector((state:IResume) => state.extractedData);
    const selectedTemplatedId = useSelector((state: any) => state.templateId);

    const handleModalClick = () => {
        setSaveAsDraftModal(false);
        navigate(modalUrls);
        saveResume()
    }

    const saveResume = async () => {
      const now = new Date();
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
      const formattedDate = now.toLocaleDateString('en-US', options).replace(',', '');

      const resume_name = `Resume - ${formattedDate}`;
      const updatedData = { ...extractedData, resume_name, template_id: selectedTemplatedId, created_at: Date.now() };

      dispatch(setExtractedData(updatedData));
      
      // Prepare the data for the POST request
      const requestData = {
          resumeJson: JSON.stringify(updatedData)
      };

      try {
          const response = await fetch('https://testapi.tekno.ai/api/add-resume', {
              method: 'POST',
              headers: {
                  'accept': '*/*',
                  'Authorization': 'Bearer ' + getToken(),
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(requestData)
          });

          if (response.ok) {
              console.log('Resume saved successfully');
          } else {
              console.error('Failed to save resume');
          }
      } catch (error) {
          console.error('Error saving resume:', error);
      }

    }

    return(
        <Modal
        onClose={()=> setSaveAsDraftModal((prev)=> !prev)}
        open={saveAsDraftModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={{...summarSectionModalstyle,width:"40.625rem"}}>
        <Box sx={{    
                    display:"flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    pt:3,
                    pl:3,
                    pr:3,
                    pb:3,
                    }}>
            <Box sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"left",gap:"1rem",marginBottom:"0.5rem"}}>
                <DraftIcon/>
                <Typography sx={{flex:"1",fontSize:"20px",fontWeight:"500",fontFamily:"inter",color:"#344054"}} id="keep-mounted-modal-title" variant="h6" component="h2">
                Are you sure to leave this page?
                </Typography>
                <Box sx={{cursor:"pointer"}} onClick={()=> setSaveAsDraftModal(false)}>
                <CancelModalIcon/>
                </Box>
            </Box>
          <Typography sx={{fontSize:"16px",fontWeight:"400",color:"#475569"}} id="keep-mounted-modal-description">
          You have unsaved changes that will be lost, if you decide to continue. 
            Do you want to save changes ?
          </Typography>
          {/* <Typography sx={{fontSize:"18px",fontWeight:"500",fontFamily:"inter",padding:"0.5rem 0"}}>"{sectionEntryModal?.}"</Typography> */}
        </Box>
        <Box sx={{width:"100%",display:"flex",justifyContent:"right",gap:"1rem",
                    pr:1,
                    pb:3,}}>
            <Button onClick={handleModalClick} sx={{border:"1px solid #cbd5e1",color:"#182230"}}>Discard</Button>
            <Button variant="contained" onClick={handleModalClick}>Save as Draft</Button>
        </Box>
        </Box>

      </Modal>
    )
};
export default SaveAsDraftModal;