import { Alert, Box, Button, Snackbar } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SnackBarState } from "../components/auth/Login";

const ProcessResumeFooter = () => {
  const [snackBar, setSnackBar] = useState<SnackBarState>({open: false, message: "", severity: "success"});
    const navigate = useNavigate();
    const url = useParams();
    const params = url["*"];
    let footerObj:{[key:string]:{prev:string|null,next:string|null}} = {};
    if (params) {
         footerObj = {
            "personal-details":{
                prev:null,
                next:"work-experience"
            },
            "work-experience" :{
                prev:"personal-details",
                next:"education",
            },
            "education":{
                prev:"work-experience",
                next:"skills",
            },
            "skills":{
                prev:"/process-resume/education",
                next:"/process-resume/summary",
            },
            "summary":{
              prev:"/process-resume/skills",
              next:null,
          }

        }
    }

    const handleCloseSnackbar = () => {
      setSnackBar({
        ...snackBar,
        open: false
      });
    };
    const handleSave = () => {
      setSnackBar({
        ...snackBar,
        message:"Resume Details saved successfully",
        open: true
      });
    }

    return (
        
          <Box className="footer">
            <Box className="footer-cancel">
            <Button variant="outlined" size="medium" 
             sx={{border:"none","&:hover":{border:"none"}}}
             onClick={()=> navigate("/")}
             >
                  Cancel
            </Button>
          </Box>
          {params && footerObj[params] && <Box className="footer-wrapper">
              { footerObj?.[params]?.prev !== null && <Button variant="outlined" size="medium" 
              onClick={()=> {
                if(footerObj[params]?.prev && typeof footerObj[params]?.prev === "string") {
                    //@ts-ignore
                  return  navigate(footerObj[params]?.prev)
                }
                }
              }
              >
                Previous
              </Button>}
                <Button variant="contained" size="medium"
                  onClick={()=> {
                      if(footerObj[params]?.next && typeof footerObj[params]?.next === "string") {
                          //@ts-ignore
                        return  navigate(footerObj[params]?.next)
                      }
                      else {
                        handleSave()
                      }
                      }}
                >
                 {footerObj?.[params]?.next === null ? "Save"  :"Next"}
                </Button>
            </Box>}

            <Snackbar open={snackBar.open} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={2500} onClose={handleCloseSnackbar}>
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackBar.severity}
              variant="filled"
              sx={{ width: '100%' }}
              >
                {snackBar.message}
              </Alert>
            </Snackbar>
          </Box>
        
    )
}
export default ProcessResumeFooter;