import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { StyledTab, StyledTabs } from "../StyledComponents/StyledTabs";
import TabPanel from "../StyledComponents/TabPanel";
import { Illustration_2 } from "../Icons";
import ResumesList from "../ResumesList";
import { Templates } from "../utils";

const HomeScreenTabs = () => {

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
  };

  const [activeTemplate, setActiveTemplate] = useState<string>("T1");

  return (
    <Box>
      <StyledTabs value={value} onChange={handleChange} aria-label="ant example">
          <StyledTab label="Recent" />
          <StyledTab label="Templates" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
          <ResumesList />
      </TabPanel>
      <TabPanel value={value} index={1}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Grid container spacing={2} sx={{height:"80vh",overflow:"auto", mt: 2}}>
              {Templates.map((template, index) => ( 
                  <Grid item xs={2} key={template.id} > {/* Adjusted for 4x4 layout */}
                  <Box
                      onClick={() => setActiveTemplate(template.id) }
                      className={`template-preview-box ${activeTemplate === template.id ? 'active' : ''}`}
                      sx={{
                          border: 1,
                          borderRadius: 2,
                          height: 340,
                          borderColor: 'grey.400', // Adjust as needed
                          minHeight: "20rem",
                          cursor: "pointer",
                      }}
                  >
                      {
                          template.img && <img src={template.img} className="template-preview-img-in-tabs"/>
                      }
                  </Box>
                  </Grid>
              ))}
            </Grid>
          </Box>
      </TabPanel>
    </Box>
  );
};

export default HomeScreenTabs;