import React, { useCallback, useEffect, useRef, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from 'react-redux';
import { StateProps } from '../redux/reducer';
import { IResume } from '../components/utils';
import Template from './ReactPDFTemplate';
import { PDFDocument as PDFLibDocument } from 'pdf-lib';

// Configure pdfjs worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const TemplatePreview = (props: any) => {
  const { pageWidth, currentPage, setNumPages, isATSPreview } = props;
  const extractedData = useSelector((state: StateProps) => state?.extractedData);
  const activeATSCheckResultID = useSelector((state: StateProps) => state?.activeATSCheckResultID);
  
  const [pdfContent, setPdfContent] = useState<string | null>(null);
  const [arrayBuffer, setArrayBuffer] = useState<ArrayBuffer | null>(null);
  const [overlayElements, setOverlayElements] = useState<any[]>([]);
  
  const pageRef = useRef<HTMLDivElement>(null);

  const handleRender = (layoutData: any) => {
    console.log('Layout data:', layoutData);
  };

  /**
   * Helper function to convert base64 string to ArrayBuffer
   */
  const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
    const binaryString = window.atob(base64.split(',')[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  /**
   * Generates the PDF content and sets up the ArrayBuffer if in ATS Preview mode
   */
  useEffect(() => {
    const generatePdfContent = async () => {
      try {
        const blob = await pdf(
          <Template 
            extractedData={extractedData} 
            pageWidth={pageWidth} 
            isATSPreview={isATSPreview} 
            onRender={handleRender} 
          />
        ).toBlob();

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          setPdfContent(base64data);

          if (isATSPreview) {
            const buffer = base64ToArrayBuffer(base64data);
            setArrayBuffer(buffer);
          } else {
            // Clear ArrayBuffer and Overlays if not in ATS Preview
            setArrayBuffer(null);
            setOverlayElements([]);
          }
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Failed to generate PDF content", error);
      }
    };

    generatePdfContent();
  }, [extractedData, isATSPreview]);

  /**
   * Calculates overlay elements based on the ArrayBuffer
   */
  const calculateOverlays = useCallback(async () => {
    if (!isATSPreview || !arrayBuffer) return;

    try {
      const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(currentPage);

      const viewport = page.getViewport({ scale: 1 });

      const pageElement = pageRef.current;
      if (!pageElement) {
        console.warn('Page element not found');
        return;
      }

      const domWidth = pageElement.clientWidth;
      const domHeight = pageElement.clientHeight;

      const scaleX = domWidth / viewport.width;
      const scaleY = domHeight / viewport.height;

      const textContent = await page.getTextContent();

      // Filter elements containing the unique identifier
      const elements = textContent.items.filter((item: any) =>
        item.str.includes('ATS_ISSUE-')
      );

      console.log('Filtered Elements:', elements);

      const overlays = elements.map((element: any) => {
        const { transform, width, height, str } = element;
        const x = transform[4];
        const y = transform[5];

        const adjustedX = x * scaleX;
        const adjustedY = domHeight - y * scaleY - (height * scaleY);

        return {
          text: str,
          x: adjustedX,
          y: adjustedY,
          width: 22,
          height: 22,
        };
      });

      setOverlayElements(overlays);
    } catch (error) {
      console.error('Failed to calculate overlays', error);
    }
  }, [arrayBuffer, currentPage, isATSPreview]);

  /**
   * Handles successful rendering of the PDF page
   */
  const handlePageRenderSuccess = async () => {
    await calculateOverlays();
  };

  /**
   * Handles click events on overlay elements
   */
  const handleOverlayClick = (item: any) => {
    console.log('Clicked overlay:', item);
    // Implement your custom click logic here
  };

  return (
    <div style={{ 
      position: 'relative', 
      width: pageWidth, 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column' 
    }}>
      {pdfContent ? (
        <div style={{ 
          flex: 1, 
          overflow: 'hidden', 
          position: 'relative' 
        }}>
          <Document
            file={pdfContent}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            onLoadError={(error) => console.error('Error loading PDF:', error)}
          >
            <div ref={pageRef}>
              <Page
                pageNumber={currentPage}
                width={pageWidth}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                onRenderSuccess={handlePageRenderSuccess}
              />
            </div>
          </Document>

          {/* Render the overlay elements only if isATSPreview is true */}
          {isATSPreview && overlayElements.map((item, index) => (
            <div
              key={index}
              onClick={() => handleOverlayClick(item)}
              style={{
                position: 'absolute',
                border: '1px solid red',
                left: `${item.x}px`,
                top: `${item.y}px`,
                width: `${item.width}px`,
                height: `${item.height}px`,
                boxSizing: 'border-box',
                cursor: "pointer",
                pointerEvents: 'auto',
              }}
              title={item.text}
            />
          ))}
        </div>
      ) : (
        <div>Loading PDF...</div>
      )}
    </div>
  );
};

export default TemplatePreview;