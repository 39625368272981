import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setLoggedInUserData } from '../../redux/actions';
import { Alert, Box, Button, Container, CssBaseline, Grid, IconButton, InputAdornment, Snackbar, TextField, Typography } from "@mui/material";
import { EmailIcon, GoogleIcon, LinkedInIcon, LockIcon, LoginPageBgComponent, VisibilityOn } from '../Icons';
import { AuthInputFieldStyle, InputFieldCustomeStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { saveToken } from './storeToken';
import { VisibilityOff } from '@mui/icons-material';
import {ReactComponent as ResumeBuilderIcon} from "../../assets/SVG/resume-builder-icon.svg";
import {ReactComponent as TeknoLogo} from "../../assets/SVG/logo.svg";
import loginBg from "../../assets/SVG/login-bg.png";
import LoginForm from './login/LoginForm';
import { SecondaryButton } from '../button/Buttons';
import LoginPageBg1 from '../../assets/SVG/LoginPageBg1.svg';
import LoginPageBg2 from '../../assets/SVG/LoginPageBg2.svg';
import { getUserDetails } from '../utils';

export interface SnackBarState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
}

const CreateAccount = () => {

  const location = useLocation();
  const navigate = useNavigate();
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBar, setSnackBar] = useState<SnackBarState>({open: false, message: "", severity: "success"});

  const dispatch = useDispatch();
  // const accessToken = useSelector((state:any) => state.accessToken);
  const accessToken = useSelector((state:any) => state.accessToken);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSocialLogin = async (provider: string) => {
    try {
      debugger
      const response = await fetch(`${process.env.REACT_APP_API_URL}/authorize?provider=${provider}`, { method: 'GET' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      window.location.href = data.authorizeUrl+'&provider='+provider;
  
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  const handleCloseSnackbar = () => {
    setSnackBar({
      ...snackBar,
      open: false
    });
  };

  // Login form submission to {process.env.REACT_APP_API_URL}/login POST endpoint
  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // const encryptedPassword = CryptoJS.AES.encrypt(formData.password, "1234567890123456").toString();
      console.log(process.env.REACT_APP_API_URL);
      const response = await fetch(`https://testapi.tekno.ai/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, /* password: encryptedPassword */ }),
      });

      if (response.ok) {
        const data = await response.json();
        //storing token to local storage
        saveToken(data.token);
        // TODO: Get the backend endpoint fixed to return the user details without the "provider" param
        getUserDetails().then((data) => {
          dispatch(data);
        });
        navigate('/dashboard', { state: { isLoginSuccessful: true } });
      } else if (response.status === 401) {
        console.error('Login failed: Invalid credentials');
        setSnackBar({
          open: true,
          severity: "error",
          message: 'Invalid credentials. Please try again.'
        });
      } else {
        const errorData = await response.json();
        console.error('Login failed:', errorData.message);
        setSnackBar({
          open: true,
          severity: "error",
          message: 'Login failed. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  useEffect(() => {
    if (location.state?.fromRegistration) {
      setSnackBar({
        open: true,
        severity: "success",
        message: 'Registration successful. Please log in.'
      });
    }
    if (location.search) {
      const signInCallback = async () => {
        const queryParams = new URLSearchParams(location.search);
        const provider = location.search && location.search.includes("googleapis") ? "Google" : "LinkedIn";
        const code = queryParams.get('code');
        const state = queryParams.get('state');

        if (code && state /* && !accessToken */) {
          try {
            // Adjust the API endpoint or parameters as needed
            const response = await fetch(`${process.env.REACT_APP_API_URL}/signin-callback?provider=${provider}&code=${code}&state=${state}`, { method: 'GET' });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const accessToken = await response.json();
            dispatch(setAccessToken(accessToken));
            navigate('/dashboard', { state: { isLoginSuccessful: true } });
          } catch (error) {
            console.error('There was an error!', error);
          }
        }
        console.log("Access Token: ", accessToken);
      };
      signInCallback();
    };
  }, [location]);

 return (
    <Grid container direction={"row"} justifyContent={"center"} style={{ display: "flex", alignItems: "center" }}>

      <Grid
        item
        md={6}
        sx={{
          background: "linear-gradient(45deg, #0045B9 0%, #3E70FF 100%)",
          height: '100vh',
          backgroundSize: '100% 100%',
          display: { xs: "none", md: "flex" },
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src={LoginPageBg1}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <Box
          component="img"
          src={LoginPageBg2}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 28
          }}
        />
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#E8EEFF' }}>
          100% Free Resume Builder
        </Typography>
        <Box mt={2}>
          <ResumeBuilderIcon
            style={{ width: '100%', maxWidth: '300px' }}
          />
        </Box>
        <Box mt={2}>
          <ul style={{ color: '#C5D6FF', textAlign: "left" }}>
            <li>Better Resume. Faster Search. More Offers.</li>
            <li>100+ Templates to choose from.</li>
            <li>AI Integration.</li>
            <li>ATS-friendly.</li>
            <li>Tailor your resume for each job application.</li>
          </ul>
        </Box>
      </Grid>
      <Grid item md={6}>
        <Container component="div" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CssBaseline />
            <Grid
              item
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding:"1rem",
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3, gap: 1 }}>
                <TeknoLogo />
                <Typography component="h5" variant="h5" sx={{ fontWeight: "bolder" }}>
                  Tekno.ai
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '1rem' }}>
                  Login to manage portal
                </Typography>
              </Box>
    
                <Box component="form" noValidate onSubmit={submitForm} sx={{ my: 3, gap: 6, mb: 4 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="label" htmlFor="email" gutterBottom sx={{ fontWeight: "bold" }}>Email *</Typography>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Enter your Email"
                        sx={AuthInputFieldStyle}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon />
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" component="label" htmlFor="password" gutterBottom sx={{ fontWeight: "bold" }}>Password *</Typography>
                      <TextField
                        required
                        fullWidth
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Enter your Password"
                        sx={AuthInputFieldStyle}
                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                sx={{ paddingRight: "0.8rem" }}
                              >
                                {showPassword ? <VisibilityOff fontSize="small" /> : <VisibilityOn />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SecondaryButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                      >
                        Sign in
                      </SecondaryButton>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  Don't have an account?&nbsp;
                  <Typography component={RouterLink} to="/register" sx={{ fontWeight: 'bold' }}>
                    Sign up
                  </Typography>
                </Box>
                <Box mb={3} sx={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Box sx={{ flex: 1, height: '1px', backgroundColor: '#E2E4E9', mr: 2 }} />
                  <Typography variant="body1" color="#868C98">
                    Login with
                  </Typography>
                  <Box sx={{ flex: 1, height: '1px', backgroundColor: '#E2E4E9', ml: 2 }} />
                </Box>
                <Box mb={3} sx={{ display: 'flex', width: "100%", justifyContent: 'space-between', gap: 2 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    className="social-login-button"
                    style={{ backgroundColor: '#fff', color: '#000', textTransform: "none", borderRadius: "10px", padding: "10px", boxShadow: "0px 1px 2px 0px rgba(228, 229, 231, 0.24)", border: "1px solid #E2E4E9"}}
                    startIcon={<LinkedInIcon />}
                    onClick={() => handleSocialLogin("LinkedIn")}
                  >
                    LinkedIn
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    className="social-login-button"
                    style={{ backgroundColor: '#fff', color: '#000', textTransform: "none", borderRadius: "10px", padding: "10px", boxShadow: "0px 1px 2px 0px rgba(228, 229, 231, 0.24)", border: "1px solid #E2E4E9"}}
                    startIcon={<GoogleIcon />}
                    onClick={() => handleSocialLogin("Google")}
                  >
                    Google
                  </Button>
                </Box>
              </Grid>
          </Container>
          <Snackbar open={snackBar.open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackBar.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Grid>
    </Grid>

  // <LoginForm/>
 );
}

export default CreateAccount;