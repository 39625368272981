// countries.js
export const countries = [
  // Asian countries
  { code: 'IN', label: 'India', flag: 'https://flagcdn.com/w20/in.png' },
  { code: 'JP', label: 'Japan', flag: 'https://flagcdn.com/w20/jp.png' },
  { code: 'CN', label: 'China', flag: 'https://flagcdn.com/w20/cn.png' },
  { code: 'KR', label: 'South Korea', flag: 'https://flagcdn.com/w20/kr.png' },
  { code: 'SG', label: 'Singapore', flag: 'https://flagcdn.com/w20/sg.png' },
  { code: 'TH', label: 'Thailand', flag: 'https://flagcdn.com/w20/th.png' },
  { code: 'MY', label: 'Malaysia', flag: 'https://flagcdn.com/w20/my.png' },
  { code: 'PH', label: 'Philippines', flag: 'https://flagcdn.com/w20/ph.png' },
  { code: 'VN', label: 'Vietnam', flag: 'https://flagcdn.com/w20/vn.png' },
  { code: 'ID', label: 'Indonesia', flag: 'https://flagcdn.com/w20/id.png' },

  // American countries
  { code: 'US', label: 'United States', flag: 'https://flagcdn.com/w20/us.png' },
  { code: 'CA', label: 'Canada', flag: 'https://flagcdn.com/w20/ca.png' },
  { code: 'BR', label: 'Brazil', flag: 'https://flagcdn.com/w20/br.png' },
  { code: 'AR', label: 'Argentina', flag: 'https://flagcdn.com/w20/ar.png' },
  { code: 'MX', label: 'Mexico', flag: 'https://flagcdn.com/w20/mx.png' },
  { code: 'CL', label: 'Chile', flag: 'https://flagcdn.com/w20/cl.png' },
  { code: 'CO', label: 'Colombia', flag: 'https://flagcdn.com/w20/co.png' },
  { code: 'PE', label: 'Peru', flag: 'https://flagcdn.com/w20/pe.png' },
  { code: 'VE', label: 'Venezuela', flag: 'https://flagcdn.com/w20/ve.png' },
  { code: 'UY', label: 'Uruguay', flag: 'https://flagcdn.com/w20/uy.png' },

  // European countries
  { code: 'GB', label: 'United Kingdom', flag: 'https://flagcdn.com/w20/gb.png' },
  { code: 'FR', label: 'France', flag: 'https://flagcdn.com/w20/fr.png' },
  { code: 'DE', label: 'Germany', flag: 'https://flagcdn.com/w20/de.png' },
  { code: 'IT', label: 'Italy', flag: 'https://flagcdn.com/w20/it.png' },
  { code: 'ES', label: 'Spain', flag: 'https://flagcdn.com/w20/es.png' },
  { code: 'NL', label: 'Netherlands', flag: 'https://flagcdn.com/w20/nl.png' },
  { code: 'BE', label: 'Belgium', flag: 'https://flagcdn.com/w20/be.png' },
  { code: 'SE', label: 'Sweden', flag: 'https://flagcdn.com/w20/se.png' },
  { code: 'CH', label: 'Switzerland', flag: 'https://flagcdn.com/w20/ch.png' },
  { code: 'PL', label: 'Poland', flag: 'https://flagcdn.com/w20/pl.png' },
  { code: 'NO', label: 'Norway', flag: 'https://flagcdn.com/w20/no.png' },
  { code: 'FI', label: 'Finland', flag: 'https://flagcdn.com/w20/fi.png' },
  { code: 'DK', label: 'Denmark', flag: 'https://flagcdn.com/w20/dk.png' },
  { code: 'IE', label: 'Ireland', flag: 'https://flagcdn.com/w20/ie.png' },
  { code: 'PT', label: 'Portugal', flag: 'https://flagcdn.com/w20/pt.png' },
  { code: 'GR', label: 'Greece', flag: 'https://flagcdn.com/w20/gr.png' },
  { code: 'AT', label: 'Austria', flag: 'https://flagcdn.com/w20/at.png' },
  { code: 'HU', label: 'Hungary', flag: 'https://flagcdn.com/w20/hu.png' },
  { code: 'CZ', label: 'Czech Republic', flag: 'https://flagcdn.com/w20/cz.png' },
  { code: 'RO', label: 'Romania', flag: 'https://flagcdn.com/w20/ro.png' },
  { code: 'BG', label: 'Bulgaria', flag: 'https://flagcdn.com/w20/bg.png' },
  { code: 'HR', label: 'Croatia', flag: 'https://flagcdn.com/w20/hr.png' },
  { code: 'SI', label: 'Slovenia', flag: 'https://flagcdn.com/w20/si.png' },
  { code: 'SK', label: 'Slovakia', flag: 'https://flagcdn.com/w20/sk.png' },
  { code: 'EE', label: 'Estonia', flag: 'https://flagcdn.com/w20/ee.png' },
  { code: 'LT', label: 'Lithuania', flag: 'https://flagcdn.com/w20/lt.png' },
  { code: 'LV', label: 'Latvia', flag: 'https://flagcdn.com/w20/lv.png' },

  // Australian countries
  { code: 'AU', label: 'Australia', flag: 'https://flagcdn.com/w20/au.png' },
  { code: 'NZ', label: 'New Zealand', flag: 'https://flagcdn.com/w20/nz.png' },
];