import { jwtDecode } from "jwt-decode";

interface JWTPayload {
  given_name?: string;
  family_name?: string;
  exp?: number; // Optional expiration timestamp, if available
}

export const getUsernameFromToken = (token: string): string | null  => {
  try {
    const decodedToken: JWTPayload = jwtDecode<JWTPayload>(token); // Decode the token with types
    console.log(decodedToken,"from token method");
    return (decodedToken.given_name && decodedToken.family_name) ? `${decodedToken.given_name} ${decodedToken.family_name}` : decodedToken.given_name || decodedToken.family_name || null;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
}