import React from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { countries } from '../helpers/countries';

interface Country {
  label: string;
  flag: string;
}

interface CountryDropdownProps {
  selectedCountry: string;
  onCountryChange: (event: React.ChangeEvent<{}>, value: string | null) => void;
  error: string;
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({ selectedCountry, onCountryChange, error }) => {
  const selectedCountryObj = countries.find(country => country.label === selectedCountry);

  return (
    <Box>
      <Typography variant="body1" component="label" htmlFor="country" gutterBottom sx={{ fontWeight: "bold" }}>Country *</Typography>
      <Autocomplete
        options={countries}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={option.flag}
              alt=""
              style={{ marginRight: '10px' }}
            />
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select your Country"
            fullWidth
            required
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedCountryObj ? (
                <img
                  loading="lazy"
                  width="20"
                  src={selectedCountryObj.flag}
                  alt=""
                  style={{ marginRight: '10px' }}
                />
              ) : null,
            }}
            error={!!error}
            helperText={error}
          />
        )}
        onChange={(event, value) => onCountryChange(event, value ? value.label : null)}
      />
    </Box>
  );
};

export default CountryDropdown;