import React, { useState, useEffect, ChangeEvent } from 'react';
import { Box, TextField, List, ListItem, ListItemText, Typography, Paper, Grid, InputAdornment, Divider, Button, IconButton } from '@mui/material';
import AppBarHeader from "../../ProcessResume/AppBarHeader";
import SideDrawer from "../../ProcessResume/SideDrawer";
import { AuthInputFieldStyle } from '../../ProcessResume/EditForms/ProcessResumeUtils';
import { FavoriteBorder, Search } from '@mui/icons-material';

type Job = {
  slug: string;
  company_name: string;
  title: string;
  description: string;
  remote: boolean;
  url: string;
  tags: string[];
  job_types: string[];
  location: string;
  created_at: number;
};

const JobBoard: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (jobs.length > 0 && !selectedJob) {
      setSelectedJob(jobs[0]);
    }
  }, [jobs]);

  useEffect(() => {
    filterJobs();
  }, [searchTerm, jobs]);

  const fetchJobs = async (search = '') => {
    const response = await fetch(`https://www.arbeitnow.com/api/job-board-api`);
    const data = await response.json();
    setJobs(data.data);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filterJobs = () => {
    const filtered = jobs.filter(job =>
      job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.location.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredJobs(filtered);
  };

  const handleJobClick = (job: Job) => {
    setSelectedJob(job);
  };

  const handleApplyClick = () => {
    
  };

  const handleSaveJobClick = () => {
    // Implement save job functionality here
    alert('Job saved!');
  };

  return (
    <Box>
      <Box className="app-bar-container">
        <AppBarHeader />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box className="side-drawer-container">
          <SideDrawer />
        </Box>
        <Box>
          <Grid item md={6} sx={{ m: 3 }}>
            <Box>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bolder" }}>
                Job Board
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ color: 'text.secondary' }}>
                Your recently created resumes for easy management and updates.
              </Typography>
            </Box>
          </Grid>
          <Box sx={{ m: 3 }}>
            <TextField
              placeholder="Search Jobs"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
              sx={AuthInputFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
            <Grid container spacing={2} sx={{ my: 1 }}>
              <Grid item xs={12} md={4}>
                <Paper sx={{ border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden' }}>
                  <Box sx={{ maxHeight: '73vh', overflow: 'auto' }}>
                    <List sx={{ p: 0 }} >
                      {filteredJobs.map((job: Job) => (
                        <React.Fragment key={job.slug}>
                          <ListItem
                            button
                            onClick={() => handleJobClick(job)}
                            selected={selectedJob?.slug === job.slug}
                            sx={{
                              backgroundColor: selectedJob?.slug === job.slug ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              },
                              padding: '16px',
                              borderBottom: '1px solid #ddd',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}
                          >
                            <ListItemText
                              primary={job.title}
                              secondary={
                                <>
                                  <Typography component="span" variant="body2" color="text.secondary">
                                    {job.location} {job.remote ? '(Remote)' : ''}
                                  </Typography>
                                </>
                              }
                            />
                            <IconButton color="primary" aria-label="add to shopping cart" onClick={() => handleApplyClick()}>
                              <FavoriteBorder />
                            </IconButton>
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                {selectedJob && (
                  <Paper sx={{ p: 2, border: '1px solid #ddd', borderRadius: '8px' }}>
                    <Typography variant="h5" gutterBottom>{selectedJob.title}</Typography>
                    <Typography variant="subtitle1" gutterBottom>{selectedJob.company_name}</Typography>
                    <Box sx={{ mt: 1 }}>
                      <Button sx={{ mr: 2 }} variant="contained" color="primary" onClick={handleApplyClick}>
                        Apply
                      </Button>
                      <Button variant="outlined" color="secondary" onClick={handleSaveJobClick}>
                        Save Job
                      </Button>
                    </Box>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: selectedJob.description }} />
                  </Paper>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default JobBoard;