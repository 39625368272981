import styles from "../Templates/Template6.module.scss";

type IComponentProps = {
    details: string[]|string,
}  

const ListTypeDetails = ({details}:IComponentProps) => {
    if(typeof details === "string") {
      return <>{details}</>
    }
      return <ul dangerouslySetInnerHTML={{ __html: details.toString() }} >
         {/* {details?.map((details, index) => (
           <li key={index} className="text-content">{details}</li>
       ))} */}
       </ul>
  }

  export default ListTypeDetails;

  // (<ul>
  //   <li>{details}</li>
  // </ul>)