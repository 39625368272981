import { styled } from '@mui/system';
import { getToken } from './auth/storeToken';

export interface IResume {
  id?: number;
  templateId?: string;
  name: {
    firstName: string;
    lastName: string;
  };
  profile_Picture: null;
  email: string;
  phone: string[];
  location: string;
  socialLinks: {
    personal_Website: string;
    linkedIn_URL: string;
    github_URL: string;
    twitter_X_URL: null;
  };
  careerObjective: string;
  designation: string;
  educationalDetails: EducationTypes[];
  workExperiences: WorkExperienceTypes[];
  projects?: CourseType[];
  awards?: CourseType[];
  references?: IReference[];
  volunteerWorks?:IReference[];
  extraCA?:IReference[];
  customSections?:{[key:string]:{label:string,value:IReference[]}};
  certifications: string;
  toolsTechnicalSkills: {label:string,value:string}[];
  nonTechnicalSkills: {label:string,value:string}[];
  programmingLanguages: string;
  interests: string;
  courses?:CourseType[];
  languages?: string[];
  hobbies?: string[];
  [key:string]:IReference[] | any;
}

export interface CourseType  {
  organization: string;
  startDate: string;
  endDate: string;
  courseDescription: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?:string;
};

export interface EducationTypes {
  universityCollege: string;
  startDate: string;
  endDate: string;
  degree: string;
  location: string;
  specialization: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
}

export interface WorkExperienceTypes {
  jobType: string;
  company:string;
  startDate: string;
  endDate: string;
  role: string;
  location: string;
  keyResponsibilities:string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
}

export interface IReference {
  organization: string;
  courseDescription: string;
  isEdit?: boolean;
  isNewEntry?: boolean;
  index?: number;
  link?:string;
}

export interface IWorkExperience {
  startDate: string;
  endDate: string;
  company: string;
  role: string;
  keyResponsibilities: string;
  projectDetails: string;
  toolsTechnologies: string;
  jobType?: string;
  location?:string;
}

export interface IProject {
  title: string;
  description: string;
  toolsTechnologies: string;
}

export interface ILoggedinUserData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;
  password: string;
};

export interface ITailoredResume {
  resumeObjectWithChanges: any;
  matchingScore: number;
  keywordsToAdd: string[];
  missingKeywords: string[];
  designation: string;
  companyName: string;
};

export interface IFetchedJobDetails {
  jobTitle: string,
  companyName: string,
  location: string,
  jobDescription: string,
  requiredSkills: string[],
  jobType: string
  salary: string
  experienceLevel: string
};

export const isValueExist = (field:string):boolean => {
  if(field && field!=="NA") {
    return true;
  }
  return false;
}

export enum TemplateCategory {
  All="All",
  Design = "Design",
  Development = "Development",
  Marketing = "Marketing",
  Sales = "Sales",
  HumanResources = "Human Resources",
  Testing = "Testing",
  Business = "Business"
}

export const Templates = [
  {
      id: "1",
      name: "Template 1",
      tag: TemplateCategory.Design,
      img: require("./../templates/preview/T1.png")
  },
  {
      id: "2",
      name: "Template 2",
      tag: TemplateCategory.Design,
      img: require("./../templates/preview/T2.png")
  },
  {
      id: "3",
      name: "Template 3",
      tag: TemplateCategory.Development,
      img: require("./../templates/preview/T3.png")
  },
  {
      id: "4",
      name: "Template 4",
      tag: TemplateCategory.Sales,
      img: require("./../templates/preview/T4.png")
  },
  {
      id: "5",
      name: "Template 5",
      tag: TemplateCategory.Marketing,
      img: require("./../templates/preview/T2.png")
  },
  {
      id: "6",
      name: "Template 6",
      tag: TemplateCategory.Testing,
      img: require("./../templates/preview/T3.png")
  },
  {
    id: "7",
    name: "Template 7",
    tag: TemplateCategory.Testing,
    img: require("./../templates/preview/T7.png")
},
{
  id: "8",
  name: "Template 8",
  tag: TemplateCategory.Testing,
  img: require("./../templates/preview/T6.png")
},
{
  id: "9",
  name: "Template 9",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T9.png")
},
{
  id: "10",
  name: "Template 10",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T10.png")
},
{
  id: "11",
  name: "Template 11",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T11.png")
},
{
  id: "12",
  name: "Template 12",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T12.png")
},
{
  id: "13",
  name: "Template 13",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T13.png")
},
{
  id: "14",
  name: "Template 14",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T14.png")
},
{
  id: "15",
  name: "Template 15",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T15.png")
},
{
  id: "16",
  name: "Template 16",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T16.png")
},
]

export const extractFaceFromPDF = async (base64string:string) => {
  try {
      const response = await fetch('https://be.tekno.ai/extract-face', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pdfBase64: base64string }),
      });
      const result = await response.json();
      if (response.ok) {
          return result.faceBase64;
      }
      return null;
  } catch (error) {
      console.error('Error converting PDF:', error);
  }
};

export const flattenExtractedData = (data: any) => {
  const { name, socialLinks, workExperiences, ...rest } = data;

  // Transform workExperiences to update keyResponsibilities
  const transformedWorkExperiences = workExperiences.map((experience: any) => ({
    ...experience,
    keyResponsibilities: experience.keyResponsibilities.split('\n').map((e:string) => ({ "keyResponsibility": e })),
  }));

  return {
    ...rest,
    firstName: name.firstName,
    lastName: name.lastName,
    careerObjective: rest.careerObjective !== "NA" ? rest.careerObjective : null,
    personal_Website: socialLinks.personal_Website !== "NA" ? socialLinks.personal_Website : null,
    linkedIn_URL: socialLinks.linkedIn_URL !== "NA" ? socialLinks.linkedIn_URL : null,
    github_URL: socialLinks.github_URL !== "NA" ? socialLinks.github_URL : null,
    twitter_X_URL: socialLinks.twitter_X_URL !== "NA" ? socialLinks.twitter_X_URL : null,
    workExperiences: transformedWorkExperiences,
  };
};

export const extractKeywords = (text: string) => {
  return text
    .toLowerCase()
    .split(/\s+/)
    .filter(Boolean);
};

const extractPhrases = (text: string, phraseLength: number) => {
  // Normalize the text by replacing special characters with spaces
  const normalizedText = text.toLowerCase().replace(/[\/\-]/g, ' ');
  const words = normalizedText.split(/\s+/).filter(Boolean);
  const phrases = [];

  for (let i = 0; i <= words.length - phraseLength; i++) {
    phrases.push(words.slice(i, i + phraseLength).join(' '));
  }

  return phrases;
};

export const checkMissingKeywords = (
  sectionValue: string | IWorkExperience[] | IProject[],
  section: string,
  keywordsToAdd: string[]
) => {
  let sectionKeywords: string[] = [];

  if (section === "careerObjective") {
    sectionKeywords = extractKeywords(stripHtmlTags(sectionValue as string));
  } else if (section === "workExperiences") {
    (sectionValue as IWorkExperience[]).forEach((experience: IWorkExperience) => {
      const keyResponsibilities = experience.keyResponsibilities;
      const keywords = extractKeywords(stripHtmlTags(keyResponsibilities));
      sectionKeywords.push(...keywords);
    });
  } else if (section === "projects") {
    (sectionValue as IProject[]).forEach((project: IProject) => {
      const description = project.description;
      const keywords = extractKeywords(stripHtmlTags(description));
      sectionKeywords.push(...keywords);
    });
  }

  const sectionPhrases = keywordsToAdd
    ?.filter(keyword => keyword.split(/\s+/).length > 1)
    ?.flatMap(keyword => {
      if (section === "workExperiences") {
        return (sectionValue as IWorkExperience[]).flatMap((experience: IWorkExperience) =>
          extractPhrases(stripHtmlTags(experience.keyResponsibilities), keyword.split(/\s+/).length)
        );
      } else if (section === "projects") {
        return (sectionValue as IProject[]).flatMap((project: IProject) =>
          extractPhrases(stripHtmlTags(project.description), keyword.split(/\s+/).length)
        );
      }
      return extractPhrases(stripHtmlTags(sectionValue as string), keyword.split(/\s+/).length);
    });

  const allSectionKeywords = new Set([...sectionKeywords, ...sectionPhrases ?? []]);

  return keywordsToAdd?.filter(
    (keyword: string) => !allSectionKeywords?.has(keyword.toLowerCase())
  );
};

export const CustomIconStyle = styled('div')<{ active: boolean; completed: boolean }>(({ theme, active, completed }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 14,  // Custom width for step icon
  height: 14, // Custom height for step icon
  borderRadius: '50%',
  backgroundColor: completed ? '#3e7ee8' : '#fff', // Background color based on state
  color: active ? '#3e7ee8' : "black", // Text color
  fontSize: '8px', // Adjust step number font size
  border: active ? '1px solid #3e7ee8' : '1px solid grey', 
}));

export const stripHtmlTags = (str: string): string => {
  return str.replace(/<\/?[^>]+(>|$)/g, "");
};

export const getUserDetails = async () => {

  const token = getToken();

  if(token === null) return;

  const response = await fetch("https://testapi.tekno.ai/api/user-profile",{
    method:"GET",
    headers:{
      'Content-type': "application/json",
      'Authorization':`Bearer ${token}`,
    },
  });

  return response.json();
}