import { Box, Chip, Typography } from "@mui/material";
import { useState } from "react";
import { languagesData } from "../../../Data/skillsData";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../../redux/actions";
import { StateProps } from "../../../redux/reducer";

interface LanguagesProps {
    handleSectionDelete:(section:string) =>void;
    sectionName:string;
}

const Languages:React.FC<LanguagesProps> = ({handleSectionDelete,sectionName}) => {
    const extractedData = useSelector((state:StateProps) => state.extractedData);
    const [languages,setLanguages] = useState(languagesData);
    const dispatch = useDispatch();

    const handleLanguageClick = (language:string) => {
        const updatedLanguages = languages.map(lan => {
          if(lan.label === language) {
            return {...lan,isSelected:!lan.isSelected }
          }
          return lan;
        })
        setLanguages(updatedLanguages);
        dispatch(setExtractedData({
            ...extractedData,
            languages:updatedLanguages?.filter((lan)=> lan.isSelected)?.map((lan)=> lan.label),
          }));
   }

  return(
    <Box className="languages-section">
    <Box className="heading-box">
        <Typography variant="h5" color="color.primary" className="heading">
         Languages
        </Typography>
        <Typography className="sub-heading" onClick={()=>handleSectionDelete(sectionName)}>Remove section</Typography>
    </Box>

    <Box
            sx={{
                marginTop:1,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1.4,
                padding:0,
                borderRadius: '8px',
            }}
            >
            {languages?.map((lan, index) => (
                <Box>
                    <Chip
                    sx={{
                    height:"2.2rem",
                    fontSize: '14px', // Adjust font size
                    fontWeight:400,
                    padding: '0.3rem', // Adjust padding
                    border: lan.isSelected ? "1px solid #5594ff" : '1px solid #e2e8f0',
                    background: lan.isSelected ? "#f7f9fd" : "#fff",
                    borderRadius: '8px', // Optional: Adjust border radius
                        '& .MuiChip-deleteIcon': {
                        fontSize: '16px', // Adjust the size of the delete icon
                        },
                    }}
                    onClick={()=>handleLanguageClick(lan.label)}  
                    label={lan.label}
                    variant="outlined"
                    // onDelete={()=> handleSkillDelete(skill.value)}
                    />
                </Box>

                ))
            }
    </Box>     
</Box>
  )
}

export default Languages;